<template>
  <div
    ref="mainTitle"
    class="main-title"
    :class="type"
  >
    <h1 ref="main">
      <slot name="title" />
    </h1>
    <h1
      v-if="type === 'loader'"
      ref="progress"
      class="progress"
    >
      <slot name="title" />
    </h1>
    <h2 v-else>
      <slot name="subtitle" />
    </h2>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => 'main',
      validator: value => ['main', 'loader']
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;
@use '~@/assets/utils/font-size' as fz;

.main-title {
  opacity: 0;
  position: relative;
  @media all and (min-width: 1022px) {
    top: 0;
  }
  &.is-inview {
    transform: translateY(0);
  }
  ::v-deep .char {
    opacity: 0;
    filter: blur(20px);
    transform: scale(1.6);
  }
  h1 {
    @include fz.display(60, 96);
    --fw: 700;
    text-align: center;
    color: #fff;
    position: relative;
    letter-spacing: -1.5px;
    line-height: 1.26;
  }

  h2 {
    --fw: 600;
    color: lighten(c.$gray, 10%);
    @include fz.display(20, 24);
    text-align: center;
    line-height: 1.25;
    position: relative;
    letter-spacing: -1.5px;
  }
  &.loader {
    pointer-events: none;
    h1 {
      color: c.$bg;
      position: relative;
      z-index: 10;
      text-shadow:
        -1px -1px 0 #444,
        1px -1px 0 #444,
        -1px 1px 0 #444,
        1px 1px 0 #444;
      @media all and (min-width: 768px) {
        text-shadow:
          -2px -2px 0 #444,
          2px -2px 0 #444,
          -2px 2px 0 #444,
          2px 2px 0 #444;
      }
      &.fill {
        text-shadow: none;
        color: #fff;
      }
    }
    .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      white-space: nowrap;
      overflow: hidden;
      color: c.$bg;
      z-index: 20;
      text-shadow:
        -1px -1px 0#fff,
        1px -1px 0 #fff,
        -1px 1px 0 #fff,
        1px 1px 0 #fff;
      @media all and (min-width: 768px) {
        text-shadow:
          -2px -2px 0#fff,
          2px -2px 0 #fff,
          -2px 2px 0 #fff,
          2px 2px 0 #fff;
      }
      transition: width 0.32s e.$out;
    }
  }
}

</style>
