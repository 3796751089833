<template>
  <div
    v-locomotive
    class="locomotive-container"
  >
    <slot />
  </div>
</template>

<script>
import LocomotiveScroll from 'locomotive-scroll'
import { mapMutations } from 'vuex'
import inviewAnimations from './inviewAnimations'

export default {
  directives: {
    locomotive: {
      inserted (el, bind, vnode) {
        vnode.context.init(el)
      }
    }
  },
  data: () => ({
    scroll: null
  }),
  methods: {
    ...mapMutations({ setScrollPosition: 'setScrollPosition', setScroller: 'setScroller', setScrollDirection: 'setScrollDirection' }),
    init (el) {
      this.scroll = new LocomotiveScroll({
        el,
        smooth: true,
        getDirection: true,
        smartphone: {
          smooth: true
        },
        tablet: {
          smooth: true
        }
      })
      this.scroll.stop()
      this.setScroller(() => this.scroll)
      // let isScrolling = false
      this.scroll.on('scroll', ({ scroll, direction, currentElements }) => {
        this.setScrollPosition(scroll.y)
        this.setScrollDirection(direction)

        // Auto scroll down at end of section
        // if (isScrolling || !direction) return
        // const currentSection = Object.values(currentElements).find((el) => el.id === el.section.id)

        // const params = {
        //   up: {
        //     startSwitch: currentSection.progress < 0.1,
        //     target: currentSection.el.previousElementSibling
        //   },
        //   down: {
        //     startSwitch: currentSection.progress > 0.8,
        //     target: currentSection.el.nextElementSibling
        //   }
        // }
        // if (!params[direction].startSwitch) return
        // this.scroll.stop()
        // isScrolling = true
        // this.scroll.scrollTo(params[direction].target, {
        //   offset: 0,
        //   duration: 2400,
        //   callback: () => {
        //     isScrolling = false
        //     this.scroll.start()
        //   }
        // })
      })
      this.scroll.on('call', (event, state, { el, progress }) => {
        if (!inviewAnimations[event]) return
        if (typeof inviewAnimations[event][state] === 'function') inviewAnimations[event][state](el, progress)
        if (typeof inviewAnimations[event] === 'function') inviewAnimations[event](el, progress)
      })
      const interval = setInterval(() => {
        this.update()
      }, 200)

      setTimeout(() => {
        clearInterval(interval)
      }, 1800)
    },
    update () {
      this.scroll.update()
    }
  }
}
</script>

<style lang="scss">
@use '~@/assets/styles/colors' as c;

.locomotive-container {
  width: 100%;
  position: relative;
  z-index: 50;
  top: 0;
  left: 0;
}
html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.c-scrollbar {
  position: absolute;
  right: 0;
  top: 0;
  width: 11px;
  height: 100%;
  transform-origin: center right;
  transition: transform 0.3s, opacity 0.3s;
  opacity: 0;
}
.c-scrollbar:hover {
  transform: scaleX(1.45);
}
.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.5;
  width: 7px;
  border-radius: 10px;
  margin: 2px;
  cursor: -webkit-grab;
  cursor: grab;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
</style>
