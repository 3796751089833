<template>
  <div
    ref="main"
    v-progressive
    data-scroll
    :data-scroll-id="id"
    class="progressive-number"
  >
    <div
      v-for="digit of digits.toString().length"
      :key="digit"
      class="digit progressive-number__text"
      :data-digit-end="to.toString().padStart(digits.toString().length, '0')[digit - 1]"
    >
      <div
        v-for="(number, i) of numbers"
        :key="i"
        class="digit__item"
        :data-digit="number"
      >
        {{ number }}
      </div>
    </div>
    <div
      v-if="unit"
      class="unit progressive-number__text"
      data-unit
    >
      {{ unit }}
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default {
  directives: {
    progressive: {
      inserted (el) {
        const digits = el.querySelectorAll('.digit')

        const setHeight = () => {
          digits.forEach((node) => {
            node.style.height = node.querySelector('.digit__item').offsetHeight + 'px'
          })
        }

        setHeight()

        window.addEventListener('resize', setHeight)
      }
    }
  },
  props: {
    id: {
      type: String,
      required: true
    },
    from: {
      type: Number,
      default: () => 0
    },
    to: {
      type: Number,
      required: true
    },
    unit: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    timeline: null,
    progress: 0,
    ready: false
  }),
  computed: {
    digits: {
      get () {
        return Math.max(this.from, this.to)
      }
    },
    numbers: {
      get () {
        return Array.from(Array(6).keys())
          .map(() => Array.from(Array(10).keys()))
          .flat()
      }
    }
  },
  methods: {
    play () {
      const digits = this.$refs.main.querySelectorAll('.digit')

      const delays = [0.1, 0.2, 0.3, 0.4, 0.5]
      digits.forEach((node, i) => {
        const end = node.dataset.digitEnd
        const items = node.querySelectorAll('.digit__item')

        const exactItems = []
        items.forEach((item) => {
          if (item.dataset.digit !== end) return
          exactItems.push(item)
        })
        const randomItem = exactItems[Math.floor(Math.random() * exactItems.length - 1) + 1]
        const delay = delays[Math.floor(Math.random() * delays.length)]
        gsap.to(node, { scrollTo: randomItem, duration: 6, delay, ease: 'power4.in' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;
@use '~@/assets/utils/font-size' as fz;

.progressive-number {
  position: relative;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  height: 100%;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  z-index: -1;
  &__text {
    color: #fff;
    --fw: 700;
    @include fz.display(42, 65);
  }
  .unit {
    @include fz.text(16, 16);
    position: relative;
    display: inline-block;
  }
  .digit {
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
