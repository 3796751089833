<template>
  <div
    id="our_solutions"
    class="container"
  >
    <h2
      class="title"
    >
      {{ $t('title') }}
    </h2>
    <div class="specs">
      <div>
        <div
          ref="keys"
          class="specs__keys"
        >
          <div
            v-for="key of Object.keys(specs)"
            :key="key"
            :class="{ active: currentSpec === key }"
            @click="currentSpec = key"
          >
            {{ $t(`labels.${key}`) }}
          </div>
          <div class="border-bottom">
            <div class="border-bottom--hl" />
            <div class="border-bottom--track" />
          </div>
        </div>
      </div>
      <div style="position: relative">
        <transition
          name="specs"
          @before-enter="specsBeforeEnter"
          @enter="specsEnter"
          @after-enter="specsAfterEnter"
          @before-leave="specsBeforeLeave"
          @leave="specsLeave"
          @after-leave="specsAfterLeave"
        >
          <div
            :key="currentSpec"
            class="specs__data"
            :class="currentSpec"
          >
            <div
              v-for="data of Object.keys(specs[currentSpec])"
              :key="data"
            >
              <div
                v-if="currentSpec !== 'custom'"
                class="specs__data__value"
                v-html="specs[currentSpec][data]"
              />
              <div
                v-else
                class="input"
              >
                <div class="mask">
                  <div
                    v-for="i of 6"
                    :key="i"
                    class="div-wrapper chars-wrapper"
                  >
                    <div class="mask__div chars" />
                  </div>
                </div>
                <input
                  v-model="form[data]"
                  type="text"
                  :name="data"
                >
                <span
                  v-if="!form[data]"
                  class="placeholder"
                  v-html="placeholders[data]"
                />
              </div>
              <div class="specs__data__key">
                {{ $t(`labels.${data}`) }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <transition name="slide">
        <div
          v-if="currentSpec === 'custom'"
          class="send-custom"
        >
          <SmallText
            class="send-custom__text"
          >
            <span v-html="$t('text')" />
          </SmallText>
          <form
            class="send-custom__form"
            @submit.prevent="sendForm"
          >
            <ValidationProvider
              v-slot="{ errors }"
              ref="provider"
              rules="required|email"
            >
              <div class="input">
                <label for="email">{{ $t('email.label') }}</label>
                <input
                  v-model="form.email"
                  type="text"
                  name="email"
                  :placeholder="$t('email.placeholder')"
                >
                <span class="underline" />
                <p
                  v-if="errors"
                  class="error"
                >
                  {{ errors[0] }}
                </p>
              </div>
            </ValidationProvider>

            <button type="submit">
              <ArrowButton side="next">
                {{ $t('send') }}
              </ArrowButton>
            </button>
          </form>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
import SmallText from '@/components/Typography/SmallText'
import ArrowButton from '@/components/ArrowButton'
import { ValidationProvider, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import axios from 'axios'
extend('email', {
  ...email,
  message: 'Formato email non valido'
})

extend('required', {
  ...required,
  message: 'Il campo è obbligatorio'
})

gsap.registerPlugin(SplitText)

export default {
  i18n: {
    messages: {
      it: {
        title: 'I modelli di STG-One',
        text: 'Inserisci i parametri che fanno al caso tuo e invia una richiesta di preventivo, riceverai un’offerta per un <strong>STG-One</strong> progettato su misura per te!',
        email: {
          label: 'Indirizzo e-mail',
          placeholder: 'Digita qui la tua email'
        },
        labels: {
          mini: 'Mini',
          plus: 'Plus',
          '225hr': '225 HR',
          custom: 'Personalizzato',
          inletHoleSize: 'Inlet Hole size',
          flatPanelDetector: 'Flat Panel Detector',
          xRaySource: 'XRay Source',
          res: 'Resolution'
        },
        send: 'Invia'
      },
      en: {
        title: 'STG-One models',
        text: 'Set your parameters and send us a request, we will contact you to discuss your needs.',
        email: {
          label: 'E-mail address',
          placeholder: 'Insert here your email'
        },
        labels: {
          mini: 'Mini',
          plus: 'Plus',
          '225hr': '225 HR',
          custom: 'Custom',
          inletHoleSize: 'Inlet Hole size',
          flatPanelDetector: 'Flat Panel Detector',
          xRaySource: 'XRay Source',
          res: 'Resolution'
        },
        custom: 'Custom',
        send: 'Send'
      }
    }
  },
  components: {
    SmallText,
    ArrowButton,
    ValidationProvider
  },
  data: () => ({
    currentSpec: 'mini',
    enterChars: null,
    enterCharsWrapper: null,
    leaveChars: null,
    leaveCharsWrapper: null,
    placeholders: {
      inletHoleSize: '000<span class="small">mm</span>',
      flatPanelDetector: '000<span class="small">x</span>000<span class="small">m</span>',
      xRaySource: '000<span class="small">kV</span>/000<span class="small">mA</span>',
      res: '000-000<span class="small">μm</span>'
    },
    specs: {
      mini: {
        inletHoleSize: '400<span class="small">mm</span>',
        flatPanelDetector: '360<span class="small">x</span>280<span class="small">mm</span>',
        xRaySource: '120<span class="small">kV</span>/200<span class="small">mA</span>',
        res: '300-500<span class="small">μm</span>'
      },
      plus: {
        inletHoleSize: '1.000<span class="small">mm</span>',
        flatPanelDetector: '430<span class="small">x</span>360<span class="small">mm</span>',
        xRaySource: '140<span class="small">kv</span>/200<span class="small">mA</span>',
        res: '200-500<span class="small">μm</span>'
      },
      '225hr': {
        inletHoleSize: '1.500<span class="small">mm</span>',
        flatPanelDetector: '430<span class="small">x</span>430<span class="small">mm</span>',
        xRaySource: '225<span class="small">kv</span>/50<span class="small">mA</span>',
        res: '70-500<span class="small">μm</span>'
      },
      custom: {
        inletHoleSize: '',
        flatPanelDetector: '',
        xRaySource: '',
        res: ''
      }
    },
    form: {
      inletHoleSize: '',
      flatPanelDetector: '',
      xRaySource: '',
      res: '',
      email: ''
    }
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  watch: {
    currentSpec: {
      handler () {
        this.animateBorderKeys()
      }
    }
  },
  mounted () {
    this.animateBorderKeys()
  },
  methods: {
    animateBorderKeys () {
      this.$nextTick(() => {
        const keys = this.$refs.keys
        const activeEl = keys.querySelector('.active')
        const scaleX = activeEl.offsetWidth / keys.offsetWidth
        const x = activeEl.offsetLeft
        gsap.to(keys.querySelector('.border-bottom--hl'), { x, scaleX, ease: 'expo.out', duration: 0.8 })
      })
    },
    specsBeforeEnter (el) {
      this.$refs.keys.style.pointerEvents = 'none'
      if (el.classList.contains('custom')) {
        this.enterCharsWrapper = {
          chars: el.querySelectorAll('.div-wrapper')
        }
        this.enterChars = {
          chars: el.querySelectorAll('.mask__div')
        }
      } else {
        this.enterCharsWrapper = new SplitText(el.querySelectorAll('.specs__data__value'), { type: 'chars', charsClass: 'chars-wrapper' })
        this.enterChars = new SplitText(this.enterCharsWrapper.chars, { type: 'chars', charsClass: 'chars' })
      }
      gsap.set(this.enterChars.chars, { xPercent: 100 })
      gsap.set(this.enterCharsWrapper.chars, { overflow: 'hidden' })
    },
    specsEnter (el) {
      const tl = gsap.timeline()

      const nodes = el.classList.contains('custom')
        ? el.querySelectorAll('.input')
        : el.querySelectorAll('.specs__data__value')

      nodes
        .forEach((node, i) => {
          tl
            .to(
              node.querySelectorAll('.chars'),
              {
                xPercent: 0,
                ease: 'expo.out',
                duration: 0.6,
                stagger: 0.02
              }, 0.2)
        })
    },
    specsAfterEnter (el) {
      this.$nextTick(() => {
        this.scroller().scroll.update()
        this.$refs.keys.style.pointerEvents = 'auto'
        if (el.classList.contains('custom')) return
        this.enterCharsWrapper.revert()
        this.enterChars.revert()
        this.enterChars = null
        this.enterCharsWrapper = null
      })
    },
    specsBeforeLeave (el) {
      if (el.classList.contains('custom')) {
        this.leaveCharsWrapper = {
          chars: el.querySelectorAll('.div-wrapper')
        }
        this.leaveChars = {
          chars: el.querySelectorAll('.mask__div')
        }
      } else {
        this.leaveCharsWrapper = new SplitText(el.querySelectorAll('.specs__data__value'), { type: 'chars', charsClass: 'chars-wrapper' })
        this.leaveChars = new SplitText(this.leaveCharsWrapper.chars, { type: 'chars', charsClass: 'chars' })
      }
      gsap.set(this.leaveCharsWrapper.chars, { overflow: 'hidden' })
    },
    specsLeave (el) {
      const nodes = el.classList.contains('custom')
        ? el.querySelectorAll('.input')
        : el.querySelectorAll('.specs__data__value')
      const tl = gsap.timeline()
      nodes
        .forEach((node, i) => {
          tl.to(
            node.querySelectorAll('.chars'),
            {
              xPercent: el.classList.contains('custom') ? 100 : -100,
              ease: 'expo.Out',
              duration: 0.6,
              stagger: 0.02
            }, 0)
        })
    },
    specsAfterLeave (el) {
      this.$nextTick(() => {
        this.scroller().scroll.update()
        if (el.classList.contains('custom')) return
        this.leaveCharsWrapper.revert()
        this.leaveChars.revert()
        this.leaveChars = null
        this.leaveCharsWrapper = null
      })
    },
    encode (data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&')
    },
    async sendForm () {
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }
      try {
        await axios.post(
          '/',
          this.encode({
            'form-name': 'specs',
            inletHoleSize: this.form.inletHoleSize,
            flatPanelDetector: this.form.flatPanelDetector,
            xRaySource: this.form.xRaySource,
            res: this.form.res,
            email: this.form.email
          }),
          axiosConfig
        )
        this.currentStep = 'complete'
        this.$gtm.trackEvent({
          event: 'contacts_form',
          category: 'Form',
          action: 'SendForm'
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/easing' as e;
@use '~@/assets/styles/colors' as c;

h2.title {
  --fw: 900;
  font-size: 7.08vmax;
  line-height: 1.055;
  text-align: center;
  color: #565454;
  padding: 0 20vw;
}

.container {
  margin-top: 12.42vw;
}

.specs {
  position: relative;
  display: block;
  width: 100%;
  margin-top: 2.42vw;
  &::after {
    content: '';
    position: absolute;
    pointer-events: none;
    width: 4.375rem;
    right: -1rem;
    top: 0;
    height: 3.5rem;
    z-index: 10;
    background: linear-gradient(270deg, c.$bg 0%, rgba(19, 19, 19, 0) 100%);;
  }
  & > div {
    max-width: 100vw;
    overflow-x: scroll;
    scrollbar-width: none;
    position: relative;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__keys {
    display: inline-flex;
    flex-wrap: nowrap;
    position: relative;
    white-space: nowrap;
    padding-bottom: 1.625rem;
    cursor: pointer;
    & > div {
      font-size: 1.5rem;
      --fw: 600;
      color: #ABABAB;
      transition: color 0.6s e.$out, opacity 0.6s e.$out;
      margin-right: 1.75rem;
      @media all and (min-width: 768px) {
        margin-right: 5.35vw;
        &:nth-last-child(2) {
          margin-right: 0;
        }
      }
      &.active {
        color: c.$main;
      }
      &:hover {
        opacity: 0.6;
      }
    }
    .border-bottom {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      height: 4px;
      &--track {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #525252;
        left: 0;
        top: 0;
        border-radius: 9999px;
      }
      &--hl {
        position: absolute;
        height: 100%;
        width: 100%;
        transform: scaleX(0);
        background-color: c.$main;
        top: 0;
        left: 0;
        z-index: 10;
        transform-origin: left;
        display: block;
        border-radius: 9999px;
      }
    }
  }
  &__data {
    // display: flex;
    // flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 1.1875rem;
    display: grid;
    grid-template-columns: 1fr;
    @media all and (min-width: 1024px) {
      grid-gap: 3vw;
      grid-template-columns: 1fr 1.2fr 1.4fr 1.2fr;
    }
    @media all and (min-width: 768px) {
      margin-top: 2.125rem;
    }
    &__key {
      color: #ABABAB;
      font-size: 0.875rem;
      margin-top: 0.5rem;
      @media all and (min-width: 768px) {
        margin-top: 1rem;
      }
    }
    & > div {
      margin-top: 2.8125rem;
      &:first-child {
        margin-top: 0;
      }
      @media all and (min-width: 768px) {
        margin-top: 0;
      }
      .input {
        position: relative;
        background: #202020;
        .mask {
          position: absolute;
          z-index: 20;
          width: 100%;
          height: 100%;
          pointer-events: none;
          display: flex;
          .div-wrapper {
            width: 16.6667%;
            height: 100%;
            position: relative;
            overflow: hidden;
          }
          &__div {
            width: 100%;
            height: 100%;
            background: c.$bg;
            position: relative;
            display: block;
            left: -100%;
          }
        }
        ::v-deep input {
          color: rgba(255, 255, 252, 1);
          border: none;
          padding: 0.75rem !important;
          background: none;
          width: auto;
          width: 100%;
          line-height: 1;
          @extend .specs__data__value;
          &:focus {
            outline: none;
            border: none;
          }
        }
        .placeholder {
          @extend .specs__data__value;
          color: rgba(255, 255, 252, 0.2) !important;
          position: absolute !important;
          left: 0;
          top: 0;
          padding: 0.75rem !important;
          z-index: 8;
          pointer-events: none;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    &__value {
      color: #fff;
      --fw: 600;
      font-size: 2.1875rem;
      line-height: 1;
      box-sizing: border-box;
      position: relative;
      padding: 0.75rem 0;
      ::v-deep .small {
        font-size: 1rem;
      }
      .chars-wrapper {
        overflow: hidden;
      }
      @media all and (min-width: 768px) {
        font-size: 2.52vw;
        height: calc(2.52vw + 1.5rem);
        ::v-deep .small {
          font-size: 1.21vw;
        }
      }
      ::v-deep div {
        display: inline-block !important;
        position: relative;
      }
    }
  }
}

.send-custom {
  display: flex;
  justify-content: space-between;
  margin-top: 3.43vw;
  flex-direction: column;
  &__form {
    margin-top: 3.2rem;
  }
  @media all and (min-width: 1024px) {
    flex-direction: row;
    &__text {
      width: 40%;
      padding-right: 10%;
    }
    &__form {
      width: 50%;
      margin-top: 0;
    }
  }
}

.specs-enter-active,
.specs-leave-active {
  transition: all 0.6s;
}

.specs-enter-active {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  .specs__data__value {
    z-index: 22;
  }
  .input {
    z-index: -1;
  }
}
</style>
