<template>
  <div
    v-software
    class="software"
  >
    <div class="software__monitor">
      <div class="software__monitor--mockup">
        <img src="/images/mockup.png">
        <transition name="fade">
          <img
            :src="`/images/software/${imgFilename}.jpg`"
            class="software-img"
          >
        </transition>
      </div>
      <div class="software__monitor--object">
        <img :src="objectImage">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import areas from '@/dataset/areas'
import { gsap } from 'gsap'
import { Flip } from 'gsap/Flip'

gsap.registerPlugin(Flip)

export default {
  directives: {
    software: {
      inserted (el, bind, vnode) {
        vnode.context.timeline = gsap.timeline({
          paused: true
        })
          .to(el, { opacity: 1, y: 0, ease: 'expo.out' }, 0)
          .add(() => {
            vnode.context.areaSwitch()
            const state = Flip.getState('.post .to-flip')
            el.classList.toggle('played')
            if (el.classList.contains('played')) {
              Flip.fit('.post .to-flip', '.software__monitor--object', { scaled: true })
            } else {
              gsap.set('.post .to-flip', { clearProps: 'all' })
            }
            Flip.from(state, {
              duration: 1,
              ease: 'expo.out',
              scale: true,
              absolute: true,
              onComplete: () => {
                gsap.set('.software__monitor--object', { opacity: 1 })
                gsap.set('.post .to-flip', { opacity: 0 })
              }
            })
          }, 0)
          .to('.software-img', { opacity: 1, ease: 'expo.out', duration: 0.2 }, 0.1)
          .to('.software__monitor--object', { opacity: 0, ease: 'expo.out', duration: 0.2 }, 0.2)
      }
    }
  },
  props: {
    currentSoftware: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    timeline: null,
    ready: false,
    areas
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' }),
    imgFilename: {
      get () {
        return this.currentSoftware.acquisitionField === 100
          ? `software-${this.currentSoftware.acquisitionField}--${this.currentSoftware.res}`
          : `software-${this.currentSoftware.acquisitionField}`
      }
    },
    objectImage: {
      get () {
        const image = this.areas[Object.keys(this.areas)[0]].mainImage
        return `/images/areas/POST_${image}`
      }
    }
  },
  watch: {
    scroller: {
      handler (scroller) {
        if (!scroller || this.ready) return
        scroller().on('scroll', ({ currentElements }) => {
          if (!currentElements.nolimits) {
            gsap.ticker.remove(this.timelineProgress)
            return
          }
          this.progress = currentElements.nolimits.progress
          gsap.ticker.add(this.timelineProgress)
          this.ready = true
        })
      },
      immediate: true
    }
  },
  methods: {
    timelineProgress () {
      if (!this.timeline) return
      // from 0.8 to 1 of section progress
      const progress = (this.progress - 0.8) / 0.12
      this.timeline.progress(progress)
    },
    areaSwitch () {
      this.$emit('area-switch', { area: 'SVoFiR' })
    }
  }
}
</script>

<style lang="scss" scoped>
.software {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: 21;
  transform: translateY(20px);
  @media all and (min-width: 768px) {
    align-items: flex-end;
  }
  &__monitor {
    position: relative;
    margin-top: 6rem;
    @media all and (min-width: 768px) {
      height: 66%;
    }
    &--mockup {
      position: relative;
      @media all and (min-width: 768px) {
        height: 100%;
      }
      img {
        width: auto;
        position: relative;
        left: 1rem;
        width: calc(100% - 2rem);
        bottom: 0;
        z-index: 5;
        @media all and (min-width: 768px) {
          height: 100%;
          width: auto;
        }
        &.software-img {
          position: absolute;
          width: 95%;
          left: 4.87%;
          top: 4.51%;
          opacity: 0;
          z-index: 2;
          @media all and (min-width: 768px) {
            top: 4.5%;
            left: 3.78%;
            height: auto;
          }
        }
      }
    }
    &--object {
      position: absolute;
      opacity: 0;
      height: 41.5%;
      bottom: 12%;
      right: 4%;
      z-index: 10;
      @media all and (min-width: 768px) {
        bottom: 15%;
        right: 4%;
        top: auto;
        height: 42%;
        left: auto;
      }
      img {
        height: 100%;
        width: auto;
      }
    }
  }
}
</style>
