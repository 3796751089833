<template>
  <div
    v-nltitle
    class="wrapper"
  >
    <transition name="slide">
      <div v-show="!area">
        <span class="nl-title no-limits-title--no">{{ $t('no') }}</span>
        <span class="nl-title no-limits-title--limits">{{ $t('limits') }}</span>
        <span class="nl-title no-limits-title--qm">?</span>
      </div>
    </transition>
    <div
      v-show="area"
      class="area"
    >
      <transition
        name="area"
        @before-enter="areaBeforeEnter"
        @enter="areaEnter"
        @before-leave="areaBeforeLeave"
        @leave="areaLeave"
      >
        <div
          v-if="typeof area === 'string'"
          :key="area"
          class="nl-title"
        >
          {{ area }}
        </div>
      </transition>
      <div
        v-if="area === 'SVoFiR'"
        class="subtitle"
      >
        Selective Volume of Fine Reconstruction
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'

gsap.registerPlugin(SplitText)

export default {
  i18n: {
    messages: {
      it: {
        no: 'Senza',
        limits: 'limiti'
      },
      en: {
        no: 'No',
        limits: 'limits'
      }
    }
  },
  directives: {
    nltitle: {
      inserted (el, bind, vnode) {
        const text = el.querySelectorAll('span')
        let marginLeft = 0
        const setPosition = () => {
          const centerPosition = window.innerWidth / 2 - (text[1].offsetWidth + text[2].offsetWidth) / 2
          const initialMarginLeft = centerPosition - text[1].getBoundingClientRect().x - text[1].offsetWidth + text[2].offsetWidth
          text[0].style.marginLeft = initialMarginLeft + 'px'
          marginLeft = text[2].offsetWidth
        }

        window.addEventListener('resize', setPosition)
        setTimeout(() => {
          setPosition()
          gsap.set(el.querySelector('div'), { transformOrigin: 'top' }, 0)

          vnode.context.timeline = gsap.timeline({ paused: true })
            .to(text[0], { marginLeft, opacity: 1, duration: 1, ease: 'expo.out' }, 0)
            .to(text[2], { opacity: 0, duration: 1.2, ease: 'expo.out' }, 0)
            .to(el.querySelector('div'), { scale: 0.73, yPercent: 0, y: 100, top: 0, duration: 0.8, ease: 'expo.out' }, 1.2)
        }, 200)
      }
    }
  },
  props: {
    area: {
      type: [String, Boolean],
      required: true
    },
    scrollDirection: {
      type: String,
      required: true
    }
  },
  data: () => ({
    ready: false,
    timeline: null,
    progress: 0,
    enterCharsWrapper: null,
    enterChars: null,
    leaveCharsWrapper: null,
    leaveChars: null
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  watch: {
    scroller: {
      handler (scroller) {
        if (!scroller || this.ready) return
        scroller().on('scroll', ({ currentElements }) => {
          if (!currentElements.nolimits) {
            gsap.ticker.remove(this.timelineProgress)
            return
          }
          this.progress = currentElements.nolimits.progress
          gsap.ticker.add(this.timelineProgress)
          this.ready = true
        })
      },
      immediate: true
    }
  },
  methods: {
    timelineProgress () {
      if (!this.timeline) return
      // from 0.1 to 0.2 of section progress
      const progress = (this.progress - 0.1) / 0.1
      this.timeline.progress(progress)
    },
    areaBeforeEnter (el) {
      this.enterCharsWrapper = new SplitText(el, { type: 'chars', charsClass: 'chars-wrapper' })
      this.enterChars = new SplitText(this.enterCharsWrapper.chars, { type: 'chars' })
      gsap.set(this.enterChars.chars, { xPercent: this.scrollDirection === 'next' ? 100 : -100 })
    },
    areaEnter () {
      gsap
        .to(
          this.enterChars.chars,
          {
            xPercent: 0,
            ease: 'power4.out',
            duration: 1.2,
            stagger: 0.05,
            delay: 0.4
          })
    },
    areaBeforeLeave (el) {
      this.leaveCharsWrapper = new SplitText(el, { type: 'chars', charsClass: 'chars-wrapper' })
      this.leaveChars = new SplitText(this.leaveCharsWrapper.chars, { type: 'chars' })
    },
    areaLeave () {
      gsap
        .to(
          this.leaveChars.chars,
          {
            xPercent: this.scrollDirection === 'next' ? -100 : 100,
            ease: 'power4.out',
            duration: 1.2,
            stagger: 0.05
          })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/easing' as e;
@use '~@/assets/styles/colors' as c;
@use '~@/assets/utils/font-size' as fz;

.wrapper {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 22;
  & > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .nl-title {
    --fw: 700;
    color: #fff;
    overflow: hidden;
    width: 100%;
    text-align: center;
    @include fz.display(48, 96);
  }
  .area {
    position: absolute;
    top: 0;
    width: 100%;
    display: inline-block;
    transform: translateY(100px) scale(0.73);
    .nl-title {
      top: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      ::v-deep .chars-wrapper {
        overflow: hidden;
        vertical-align: bottom;
      }
    }
    .subtitle {
      color: c.$gray;
      --fw: 700;
      height: auto;
      position: relative;
      text-align: center;
      @include fz.display(18, 25);
      top: 4.5rem;
      @media all and (min-width: 1024px) {
        top: 8rem;
      }
    }
  }
  span {
    &:first-child {
      margin-right: 2.2vw;
      opacity: 0;
    }
  }
}

.area-enter-active,
.area-leave-active {
  transition: all 2s e.$out;
}
</style>
