<template>
  <div class="data">
    <VerySmallTitle
      data-scroll
      data-scroll-call="line-by-line"
      data-scroll-offset="20%, 20%"
    >
      <div v-force-i18n="{ locale: $i18n.locale, message: $t('title1') }">
        {{ $t('title1') }}
      </div>
    </VerySmallTitle>
    <VerySmallTitle
      data-scroll
      data-scroll-call="line-by-line"
      data-scroll-offset="20%, 20%"
    >
      <div v-force-i18n="{ locale: $i18n.locale, message: $t('title2') }">
        {{ $t('title2') }}
      </div>
    </VerySmallTitle>
    <div
      class="number"
      data-scroll
      data-scroll-call="char-by-char"
      data-scroll-offset="40%"
    >
      <div>400</div>
      <span>mm</span>
    </div>
    <div
      class="number"
      data-scroll
      data-scroll-call="char-by-char"
      data-scroll-offset="40%"
    >
      <div>900</div>
      <span>mm</span>
    </div>
  </div>
</template>

<script>
import VerySmallTitle from '@/components/Typography/VerySmallTitle'

export default {
  i18n: {
    messages: {
      it: {
        title1: 'Diametro a partire da',
        title2: 'Field of View fino a'
      },
      en: {
        title1: 'Diameter starting from',
        title2: 'Field of View up to'
      }
    }
  },
  components: {
    VerySmallTitle
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/utils/font-size' as fz;

.data {
  margin-top: 2.625rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, minmax(20px, 1fr));
  grid-gap: 0.75rem 3rem;
  grid-auto-flow: row;
  z-index: -1;
  .number {
    color: #fff;
    --fw: 700;
    grid-row: 2 / 4;
    display: flex;
    align-items: baseline;
    @include fz.display(35, 65);
    span {
      @include fz.display(16, 22);
    }
  }
}
</style>
