<template>
  <div
    class="contacts-button"
    @click="handleClick"
  >
    <div class="bg" />
    <div class="content">
      <span class="content__arrow">
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 14 9"
        ><path
          d="M2 2l5 5 5-5"
          stroke="#131313"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg>
      </span>
      <div class="content__label">
        {{ $t('contactUs') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  i18n: {
    messages: {
      it: {
        contactUs: 'Contattaci'
      },
      en: {
        contactUs: 'Contact us'
      }
    }
  },
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  methods: {
    handleClick () {
      this.scroller().el.classList.add('can-t-stop')
      this.scroller().scrollTo('#contacts', {
        callback: () => {
          this.scroller().el.classList.remove('can-t-stop')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;

.contacts-button {
  position: fixed;
  top: 100vh;
  z-index: 60;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  height: 2.375rem;
  width: 7.75rem;
  cursor: pointer;
  @media all and (min-width: 768px) {
    right: 0;
    top: 50%;
    width: 3.5rem;
    height: 12.125rem;
    bottom: auto;
    transform: translate3d(100%, -50%, 0);
    left: auto;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: c.$main;
      border-radius: 0.75rem 0 0 0.75rem;
      z-index: -1;
      filter: blur(8px);
      opacity: 0;
      transform-origin: top;
      transition: opacity 0.4s e.$out, transform 0.4s e.$in-out;
    }
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
    background-color: c.$main;
    border-radius: 0.75rem 0.75rem 0 0;
    transform-origin: top;
    transition: transform 0.4s e.$in-out;
    @media all and (min-width: 768px) {
      border-radius: 0.75rem 0 0 0.75rem;
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    @media all and (min-width: 768px) {
      width: auto;
      height: auto;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) rotate(-90deg);
      position: absolute;
    }
    &__label {
      position: relative;
      color: c.$bg;
      --fw: 600;
      z-index: 10;
      font-size: 0.875rem;
      order: 1;
      white-space: nowrap;
      @media all and (min-width: 768px) {
        font-size: 1.375rem;
        order: 2;
      }
    }
    &__arrow {
      width: 0.5rem;
      order: 2;
      position: relative;
      margin-left: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      svg {
        width: 100%;
      }
      @media all and (min-width: 768px) {
        margin-left: 0;
        margin-right: 0.625rem;
        order: 1;
        width: 1rem;
        min-width: 1rem;
        transform: rotate(90deg);
      }
    }
  }
  @media all and (min-width: 768px) {
    &:hover {
      &::after {
        opacity: 1;
        transform: scaleY(1.032);
      }
      .bg {
        transform: scaleY(1.032);
      }
      .content__arrow {
        animation: arrow 1.6s both e.$in-out infinite;
      }
    }
  }
}

@keyframes arrow {
  0%,
  100% {
    transform: translateX(0) rotate(90deg);
    opacity: 1;
  }
  49% {
    transform: translateX(-10px) rotate(90deg);
    opacity: 0;
  }
  50% {
    transform: translateX(5px) rotate(90deg);
    opacity: 0;
  }
}

</style>
