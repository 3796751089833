<template>
  <div
    ref="button"
    class="main-button"
    :class="[status, { reduced }]"
  >
    <div class="main-button__bg" />
    <div
      v-if="selector"
      class="main-button__selector"
    >
      <svg
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="50"
          cy="50"
          r="45"
        />
      </svg>
    </div>
    <div class="main-button__label">
      <slot />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { Flip } from 'gsap/Flip'

gsap.registerPlugin(Flip)

export default {
  props: {
    status: {
      type: String,
      default: () => 'active',
      validator: value => ['active', 'disabled', 'selected']
    },
    selector: {
      type: Boolean,
      default: () => true
    }
  },
  data: () => ({
    reduced: false
  }),
  methods: {
    reduce () {
      if (this.reduced) return
      const btn = this.$refs.button
      const state = Flip.getState(btn)
      btn.classList.add('reduced')
      Flip.from(state, {
        duration: 0.6,
        ease: 'expo.out',
        onComplete: () => {
          this.reduced = true
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;

.main-button {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  @media all and (min-width: 768px) {
    padding: 1.125rem;
    min-width: 14.25rem;
  }
  cursor: pointer;
  @media all and (min-width: 1600px) {
    padding: 0.9vw;
    min-width: 11.5vw;
  }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background-color: #363636;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: transform 1.2s e.$out, opacity 1.2s e.$out;
    &::after {
      content: '';
      position: absolute;
      background-color: c.$main;
      width: 32rem;
      height: 32rem;
      border-radius: 50%;
      opacity: 0;
      bottom: -90%;
      left: 50%;
      transform: scale(0) translateX(-50%);
      transition: transform 0.4s e.$in-out, opacity 0.4s e.$in-out;
      @media all and (min-width: 1600px) {
        width: 35vw;
        height: 35vw;
      }
    }
  }
  &__selector {
    position: relative;
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    border: 1px solid c.$main;
    z-index: 2;
    backface-visibility: hidden;
    transition: border-color 1.2s e.$out;
    @media all and (min-width: 1600px) {
      width: 1.11vw;
      height: 1.11vw;
    }
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      top: 0;
      left: 0;
      background-color: c.$bg;
      transition: transform 1.2s e.$in-out, opacity 1.2s e.$in-out;
    }
    svg {
      position: absolute;
      z-index: 10;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
      backface-visibility: hidden;
      circle {
        fill: transparent;
        stroke: c.$bg;
        stroke-width: 12;
        stroke-dasharray: 283;
        stroke-dashoffset: 283;
        transform-origin: 50% 50%;
      }
    }
  }
  &__label {
    position: relative;
    --fw: 600;
    color: #fff;
    font-size: 0.875rem;
    margin-left: 0.5rem;
    transition: opacity 1.2s e.$out, color 1.2s e.$out;
    @media all and (min-width: 1600px) {
      font-size: 1.11vw;
      margin-left: 1.06vw;
    }
  }
  &:hover {
    .main-button {
      &__bg {
        opacity: 0.8;
        transform: scale(1.1);
      }
      &__selector {
        svg {
          animation: 1.4s e.$out svg--animation;
          circle {
            animation: 1.2s e.$out both circle--animation;
          }
        }
      }
      &__label {
        opacity: 0.7;
      }
    }
  }
  &:not(.reduced) {
    &.selected {
      pointer-events: none;
      .main-button {
        &__bg {
          &::after {
            transform: scale(1) translateX(-50%);
            opacity: 1;
          }
        }
        &__selector {
          border-color: c.$bg;;
          &::after {
            transform: scale(1);
            opacity: 1;
          }
        }
        &__label {
          color: c.$bg;
        }
      }
    }
  }
  &.reduced {
    opacity: 0.4;
    transition: opacity 0.4s e.$out;
    min-width: 0;
    padding: 0;
    .main-button {
      &__bg {
        opacity: 0;
        transform: scale(0);
      }
      &__selector {
        width: 1rem;
        height: 1rem;
        background-color: #fff;
        border-color: transparent;
        svg {
          display: none;
        }
      }
      &__label {
        font-size: 1rem;
        color: #fff;
      }
    }
    &.selected {
      opacity: 1;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

@keyframes circle--animation {
  0%,
  25% {
    stroke-dashoffset: 283;
    transform: rotate(0);
  }
  75%,
  100% {
    stroke-dashoffset: 0;
    transform: rotate(45deg);
  }
}

@keyframes svg--animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg)
  }
}
</style>
