<template>
  <div
    class="container software-controls"
    data-scroll
    data-scroll-call="fade-in"
    data-scroll-offset="20%"
  >
    <div>
      <h2>
        {{ $t('fieldOfView') }}
      </h2>
      <div class="buttons">
        <MainButton
          v-for="field of acquisitionFields"
          :key="field"
          :status="values.acquisitionField === field ? 'selected' : 'active'"
          class="button"
          @click.native="handleClick(field)"
        >
          {{ field }}mm
        </MainButton>
      </div>
    </div>
    <div>
      <h2>{{ $t('res') }}</h2>
      <div
        class="buttons"
        :class="{ disabled: values.acquisitionField !== 100 }"
      >
        <Toggle
          :value="values.res === 'low'"
          :labels="['Low', 'High']"
          :status="values.acquisitionField === 100 ? 'active' : 'disabled'"
          @click.native="handleToggle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from '@/components/MainButton'
import Toggle from '@/components/Toggle'

export default {
  i18n: {
    messages: {
      it: {
        fieldOfView: 'Campo di acquisizione',
        res: 'Risoluzione'
      },
      en: {
        fieldOfView: 'Field of view',
        res: 'Resolution'
      }
    }
  },
  components: {
    MainButton,
    Toggle
  },
  data: () => ({
    acquisitionFields: [
      100,
      200,
      500
    ],
    values: {
      acquisitionField: 100,
      res: 'hi'
    }
  }),
  methods: {
    handleToggle () {
      this.values.res = this.values.res === 'hi'
        ? 'low'
        : 'hi'
      this.$emit('software-change', this.values)
    },
    handleClick (field) {
      this.values.acquisitionField = field
      this.$emit('software-change', this.values)
    }
  }
}
</script>

<style lang="scss" scoped>
.software-controls {
  margin-top: -1.5rem;
  margin-bottom: 6.82vw;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr;
  @media all and (min-width: 1024px) {
    grid-template-columns: auto auto;
  }
  & > div {
    &:last-child {
      margin-top: 2.5rem;
      @media all and (min-width: 1024px) {
        padding-left: 5vw;
        margin-top: 0;
      }
    }
    h2 {
      color: #fff;
      font-size: 1.375rem;
      --fw: 600;
      @media all and (min-width: 1600px) {
        font-size: 1.11vw;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 2.125rem;
      @media all and (min-width: 1024px) {
        margin-top: 2.52vw;
        justify-content: flex-start;
      }
      .button {
        margin-top: 1.375rem;
        margin-right: 1rem;
        @media all and (min-width: 1024px) {
          margin-top: 0;
          margin-right: 1.2rem;
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
}
</style>
