<template>
  <div
    :id="sectionId"
    class="sticky-section"
    :style="{ height }"
  >
    <div
      class="main-content container"
      data-scroll
      data-scroll-sticky
      :data-scroll-target="`#${sectionId}`"
      :data-scroll-id="sectionId"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    vh: {
      type: Number,
      default: () => 2
    },
    sectionId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    fixContent: false
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' }),
    height: {
      get () {
        return window.innerHeight > 1024
          ? this.vh * 100 + 'vh'
          : this.vh * 50 + 'vh'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  .main-content {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    align-items: center;
  }
}
</style>
