<template>
  <p
    v-if="!inviewAnimation"
    :class="[ alignment, noMargin ]"
  >
    <slot />
  </p>
  <p
    v-else
    :class="[ alignment, noMargin ]"
    data-scroll
    :data-scroll-call="inviewAnimation"
    data-scroll-offset="40%, 40%"
  >
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    alignment: {
      type: String,
      default: () => 'left',
      validator: value => ['left', 'center', 'right']
    },
    noMargin: {
      type: Boolean,
      default: () => false
    },
    inviewAnimation: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/utils/font-size' as fz;

p {
  margin-top: 2.625rem;
  line-height: 1.45;
  color: c.$gray;
  --fw: 600;
  ::v-deep span {
    display: inline;
    strong {
      color: #fff;
    }
  }
  @include fz.text(18, 22);
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  strong {
    --fw: 700;
    color: #fff;
  }
  &.noMargin {
    margin: 0;
  }
}
</style>
