<template>
  <div
    v-click-outside="closeSelector"
    class="locale-button"
  >
    <MainButton
      :selector="false"
      @click.native="toggleSelector"
    >
      <div class="button-wrapper">
        <span>Language</span>
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 15"
        ><path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.785 7.76l1.847 1.834-.552 1.488L5.82 8.81l-3.638 3.659-1.03-1.036 3.7-3.676a12.868 12.868 0 01-2.169-3.337H4.14a11.589 11.589 0 001.68 2.452 11.55 11.55 0 002.305-3.92H0V1.495h5.09V.03h1.456v1.464h5.087v1.468H9.502a12.822 12.822 0 01-2.697 4.777l-.02.02zm4.486-1.876h1.456L16 14.662h-1.456l-.818-2.194h-3.454l-.818 2.194H7.998l3.273-8.778zm.73 1.947l-1.183 3.172h2.366L12 7.831z"
          fill="#fff"
        /></svg>
      </div>
    </MainButton>
    <transition name="selector">
      <div
        v-if="showSelector"
        class="locale-selector"
      >
        <div
          :class="{ active: $i18n.locale === 'it' }"
          @click="switchLocale('it')"
        >
          Italiano
        </div>
        <div
          :class="{ active: $i18n.locale === 'en' }"
          @click="switchLocale('en')"
        >
          English
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MainButton from '@/components/MainButton'

export default {
  directives: {
    clickOutside: {
      inserted (el, bind) {
        window.addEventListener('click', (e) => {
          if (!el.contains(e.target)) {
            if (typeof bind.value !== 'function') return
            bind.value()
          }
        })
      }
    }
  },
  components: {
    MainButton
  },
  data: () => ({
    showSelector: false
  }),
  watch: {
    '$i18n.locale': {
      handler () {
        this.closeSelector()
      }
    }
  },
  methods: {
    toggleSelector () {
      this.showSelector = !this.showSelector
    },
    closeSelector () {
      this.showSelector = false
    },
    switchLocale (locale) {
      this.$i18n.locale = locale
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/easing' as e;
@use '~@/assets/styles/colors' as c;

.locale-button {
  position: relative;
  ::v-deep .main-button {
    min-width: 0;
    &__bg {
      background-color: c.$bg;
      transition: transform 0.8s e.$out, background-color 0.4s e.$out;
    }
    .button-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      svg {
        width: 1rem;
        margin-left: 0.5rem;
        min-width: 0;
      }
    }
    &:hover {
      .main-button__bg {
        background-color: lighten(c.$bg, 6%);
      }
    }
  }
  .locale-selector {
    position: absolute;
    left: 0;
    border-radius: 1.25rem;
    overflow: hidden;
    width: 100%;
    margin-top: 0.5rem;
    div {
      cursor: pointer;
      color: #fff;
      font-size: 0.875rem;
      padding: 1.125rem 0.25rem;
      text-align: center;
      display: flex;
      justify-content: center;
      --fw: 700;
      background-color: c.$bg;
      transition: background-color 0.4s e.$out;
      &.active {
        pointer-events: none;
        color: c.$main;
      }
      &:hover {
        background-color: lighten(c.$bg, 6%);
      }
    }
  }
}

.selector-enter-active,
.selector-leave-active {
  transition: opacity 0.6s e.$in-out, transform 0.6s e.$in-out;
}

.selector-enter,
.selector-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
