<template>
  <div
    class="container"
    data-scroll
    data-scroll-id="intro-data"
  >
    <StickySection
      section-id="data"
      :vh="5"
    >
      <div class="content">
        <Title
          class="title"
          data-scroll
          data-scroll-call="line-by-line"
          data-scroll-offset="20%, 20%"
          data-scrolltosection
        >
          <div v-force-i18n="{ locale: $i18n.locale, message: $t('title') }">
            {{ $t('title') }}
          </div>
        </Title>
        <div class="content__wrapper">
          <transition
            :name="numbersTransition"
          >
            <Numbers v-show="currentComponent === 'numbers'" />
          </transition>
          <transition
            :name="descriptionTransition"
          >
            <Description v-show="currentComponent === 'description'" />
          </transition>
        </div>
      </div>
    </StickySection>
  </div>
</template>

<script>
import Title from '@/components/Typography/Title'
import StickySection from '@/components/StickySection'
import Numbers from './Numbers'
import Description from './Description'
import { mapGetters } from 'vuex'

export default {
  i18n: {
    messages: {
      it: {
        title: 'Versatile oltre ogni limite'
      },
      en: {
        title: 'Flexible beyond all limits'
      }
    }
  },
  components: {
    Title,
    StickySection,
    Numbers,
    Description
  },
  data: () => ({
    init: false,
    currentComponent: 'numbers',
    numbersTransition: 'slide',
    descriptionTransition: 'slide-delay',
    oldProgress: 0
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  watch: {
    scroller: {
      handler (scroller) {
        if (!scroller || this.init) return
        scroller().on('scroll', ({ currentElements }) => {
          if (!currentElements.data) return
          if (this.oldProgress < 0.5) {
            this.numbersTransition = 'slide'
            this.descriptionTransition = 'slide-delay'
          } else {
            this.numbersTransition = 'slide-delay'
            this.descriptionTransition = 'slide'
          }
          if (currentElements.data.progress < 0.5) {
            this.currentComponent = 'numbers'
            this.oldProgress = currentElements.data.progress
            return
          }
          this.currentComponent = 'description'
          this.oldProgress = currentElements.data.progress
        })
        this.init = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/easing' as e;

::v-deep .main-content {
  justify-content: flex-end;
  transform: translate3d(0, 0, 0);
  top: -10vh;
  position: relative;
  @media all and (min-width: 1024px) {
    top: 0;
  }
  .title {
    z-index: 20;
  }
  .content {
    @media all and (min-width: 768px) {
      width: 50%;
      h2 {
        width: 70%;
      }
    }
    &__wrapper {
      height: 180px;
      z-index: -1;
    }
  }
}
</style>
