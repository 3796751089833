<script>
// import chunk from 'lodash.chunk'
import dataSections from '@/dataset/frame-by-frame-animations'
import { mapMutations } from 'vuex'

const canUseWebP = () => {
  const testEl = document.createElement('canvas')
  if (testEl.getContext && testEl.getContext('2d')) {
    return testEl.toDataURL('image/webp').indexOf('data:image/webp') === 0
  }
  return false
}

export default {
  async mounted () {
    await Promise.all(
      Array.from(
        Array(dataSections.introduction.totalFrames).keys()
      )
        .map(async (index) => this.loadImage('introduction', index))
    )

    this.setAppReady(true)

    await Promise.all(
      Array.from(
        Array(dataSections.ring_rotation.totalFrames).keys()
      )
        .map(async (index) => this.loadImage('ring_rotation', index))
    )
  },
  methods: {
    ...mapMutations({ pushImage: 'pushImage', setAppReady: 'setAppReady' }),
    loadImage (section, index) {
      const prefExt = canUseWebP() ? 'webp' : 'jpg'
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.src = `/frames/${section}/DIMENSIONE_JPG_${(index).toString().padStart(3, '0')}.${prefExt}`
        image.onload = () => {
          this.pushImage({ section, index, image })
          return resolve(image)
        }
      })
    }
  },
  render: () => ''
}
</script>
