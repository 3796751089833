<template>
  <TextButton
    class="text-button"
    :type="type"
  >
    <div
      class="arrow-button"
      :class="type"
    >
      <div
        v-if="side === 'prev'"
        class="arrow--prev"
      >
        <svg
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 9 13"
        ><path
          d="M7 1.5l-5 5 5 5"
          :stroke="type === 'default' ? '#61F48C' : '#fff'"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        /></svg>
      </div>
      <div><slot /></div>
      <div
        v-if="side === 'next' || side === 'down'"
        class="arrow--next"
        :class="{ 'arrow--next--down': side === 'down' }"
      >
        <svg
          viewBox="0 0 9 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 11.5L7 6.5L2 1.5"
            :stroke="type === 'default' ? '#61F48C' : '#fff'"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </TextButton>
</template>

<script>
import TextButton from '@/components/TextButton'

export default {
  components: {
    TextButton
  },
  props: {
    side: {
      type: String,
      required: true,
      validator: value => ['prev', 'next', 'down']
    },
    type: {
      type: String,
      default: () => 'default',
      validator: value => ['default', 'small']
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/easing' as e;

.text-button {
  .arrow-button {
    display: flex;
    align-items: center;
    .arrow {
      width: 0.5rem;
      transition: transform 1s e.$out;
      &--next {
        @extend .arrow;
        margin-left: 0.5rem;
        &--down {
          transform: rotate(90deg);
        }
      }
      &--prev {
        @extend .arrow;
        margin-right: 0.5rem;
      }
    }
    &.small {
      .arrow {
        width: 0.375rem;
        top: 0.125rem;
        position: relative;
      }
    }
  }
  &:hover {
    .arrow {
      &--next {
        transform: translateX(6px);
      }
      &--prev {
        transform: translateX(-6px);
      }
    }
  }
}
</style>
