<template>
  <div
    id="contacts"
    class="container"
  >
    <ContactsIntro />
    <ContactsStepForm />
  </div>
</template>

<script>
import ContactsIntro from './ContactsIntro'
import ContactsStepForm from './ContactsStepForm'

export default {
  components: {
    ContactsIntro,
    ContactsStepForm
  }
}
</script>
