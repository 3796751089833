<template>
  <transition name="slide-in">
    <div
      v-if="scrollPosition > 1000 && scrollDirection === 'up'"
      class="back-to-top"
      @click="scrollToTop"
    >
      <div class="bg" />
      <div class="arrow">
        <div class="wrapper">
          <div>
            <span />
          </div>
          <div>
            <span />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ scroller: 'scroller', scrollPosition: 'scrollPosition', scrollDirection: 'scrollDirection' })
  },
  methods: {
    scrollToTop () {
      if (!this.scroller()) return
      this.scroller().scrollTo(0, {
        duration: Math.min(1000, this.scrollPosition * 0.5)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/easing' as e;

.back-to-top {
  position: fixed;
  z-index: 51;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  bottom: 0.5rem;
  right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  @media all and (min-width: 768px) {
    bottom: 4rem;
    right: 4rem;
    width: 3.75rem;
    height: 3.75rem;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    box-shadow: -4px 4px 35px rgba(0, 0, 0, 0.25);
    transition: transform 0.6s e.$in-out;
  }
  .arrow {
    position: relative;
    top: -0.2rem;
    display: inline-block;
    @media all and (min-width: 768px) {
      top: -0.3rem;
    }
    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      animation: scroll-down 1.6s linear infinite;
      div {
        position: relative;
        height: 2px;
        width: 0.675rem;
        overflow: hidden;
        display: block;
        transition: all 0.2s e.$in-out;
        @media all and (min-width: 768px) {
          width: 1rem;
        }
        &:first-child {
          transform-origin: right;
          transform: rotate(-45deg);
        }
        &:last-child {
          transform-origin: left;
          transform: rotate(45deg);
        }
        span {
          height: 100%;
          width: 100%;
          position: relative;
          display: block;
          background-color: #fff;
        }
      }
    }
  }
  &:hover {
    .bg {
      transform: scale(1.2);
      box-shadow: -8px 8px 35px rgba(0, 0, 0, 0.32);
    }
    .arrow {
      .wrapper {
        animation-play-state: paused;
        div {
          &:first-child {
            span {
              animation: span-left 0.8s e.$in-out both;
            }
          }
          &:last-child {
            span {
              animation: span-right 0.8s e.$in-out both 0.2s;
            }
          }
        }
      }
    }
  }
}

@keyframes span-left {
  0%,
  100% {
    transform: translateX(0);
    opacity: 1;
  }
  49.999% {
    transform: translateX(100%);
    opacity: 0;
  }
  50% {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes span-right {
  0%,
  100% {
    transform: translateX(0);
    opacity: 1;
  }
  49.999% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
