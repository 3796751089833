<template>
  <div
    class="container--small"
    data-scroll
    data-scroll-id="intro-maintext"
  >
    <BigText
      data-scroll
      data-scroll-call="line-by-line"
      data-scroll-offset="40%, 40%"
      data-scroll-repeat
      data-scrolltosection
    >
      <div v-force-i18n="{ locale: $i18n.locale, message: $t('text') }">
        {{ $t('text') }}
      </div>
      <TextButton
        class="button"
        @click.native="scrollToNextSection"
      >
        <span v-force-i18n="{ locale: $i18n.locale, message: $t('button') }">{{ $t('button') }}</span>
      </TextButton>
    </BigText>
  </div>
</template>

<script>
import BigText from '@/components/Typography/BigText'
import TextButton from '@/components/TextButton'
import { mapGetters } from 'vuex'
import { gsap } from 'gsap'

export default {
  i18n: {
    messages: {
      it: {
        text: 'Versatile, preciso, senza eguali. Il tuo alleato per controlli non distruttivi che scansiona in 3D anche oggetti di grandi dimensioni. Per determinare la qualità del tuo prodotto rendendolo più sicuro.',
        button: 'Scopri le soluzioni'
      },
      en: {
        text: 'Versatile, accurate, unique. Your partner for non-destructive testing that provides 3D data even on large objects. For the safety of every single product.',
        button: 'Discover our solutions'
      }
    }
  },
  components: {
    BigText,
    TextButton
  },
  data: () => ({
    tl: null
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  watch: {
    scroller: {
      handler (scroller) {
        if (!scroller) return
        scroller().on('scroll', ({ currentElements }) => {
          if (!currentElements.introtext) return
          this.tlProgress(currentElements.introtext.progress)
        })
      }
    }
  },
  mounted () {
    this.tl = gsap.timeline({ paused: true })
      .to('.canvas-container .overlay', { backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 19%, rgba(0,0,0,0) 72%, rgba(0,0,0,0) 100%)', ease: 'expo.out', duration: 1 })
      .to('.canvas-container .overlay', { backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.9) 19%, rgba(0,0,0,0.32) 72%, rgba(0,0,0,0) 100%)', ease: 'expo.out', duration: 4 }, 12)
      .to('.canvas-container .overlay', { backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 19%, rgba(0,0,0,0) 72%, rgba(0,0,0,0) 100%)', ease: 'expo.out', duration: 2 })
  },
  methods: {
    scrollToNextSection () {
      this.scroller().el.classList.add('can-t-stop')
      this.scroller().scrollTo('#our_solutions', {
        callback: () => {
          this.scroller().el.classList.remove('can-t-stop')
        }
      })
    },
    tlProgress (progress) {
      gsap.ticker.add(() => {
        this.tl.progress(progress)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container--small {
  text-align: center;
  overflow: hidden;
  padding-top: 40vh;
  .button {
    display: block;
    text-align: center;
    width: 100%;
  }
}
</style>
