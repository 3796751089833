export default {
  Aerospace: {
    mainImage: 'aerospace.png',
    size: 1,
    subsectors: {
      helicopters: {
        title: {
          it: 'Helicopters',
          en: 'Helicopters'
        },
        description: {
          it: 'Tipicamente di lunghezza notevole, le main rotor blade vengono scansionate in maniera ottimale grazie alla soluzione tecnologica a gantry dello STG mini. con uno scan rate da 18min/m e un voltaggio da 100-130 kV si ottengono ricostruzioni dei volumi locali fino a 70 µm, e totali da 350µm, il tutto reso possibile dall’algoritmo Svofir',
          en: 'Typically of considerable length, main rotor blades are scanned in the best possible way thanks to STG mini’s gantry technology solution. With a scan rate of 18min/m and a voltage of 100-130 kV reconstructions of local volumes up to 70 µm and total volumes from 350µm can be achieved, all made possible by Svofir algorithm.'
        }
      },
      satellite: {
        title: {
          it: 'Satellite',
          en: 'Satellite'
        },
        description: {
          it: 'Strutture di supporto, elementi di fissaggio, componenti incollati sono particolari molto importanti nella realizzazione di satelliti. STG mini o Plus risponde perfettamente alla necessità di scansionare con un ottimo grado di risoluzione particolari difficilmente ispezionabili con le tecniche NDI convenzionali. STG plus è stato impiegato in diverse occasioni per l’ispezione di brackets a geometrie complessa (corpi scatolati e/o non accessibili da entrambe i lati) dove si necessitava di una caratterizzazione della qualità del laminato. Impostando un FoV da 500mm (STG Plus) è possibile scansionare simultaneamente numerosi brackets con risoluzioni fino a 100µm ottenendo sia una caratterizzazione difettologica del materiale che un processo molto veloce.',
          en: 'Secondary structures, Fixing and fastening elements, bonded components are such important details during construction of a satellite. When tricky to inspect with conventional NDI techniques, STG mini or Plus meets the need to scan these objects with an excellent degree of resolution. STG plus has been used on several projects for the inspection of brackets with complex geometries (boxed bodies and/or not accessible from both sides) where we had to assess laminate quality. By setting a 500mm FoV (STG Plus) it’s possible to scan several brackets at the same time with resolutions up to 100µm, getting both a defect assessment analysis of the material and a very fast process.'
        }
      },
      primaryStructures: {
        title: {
          it: 'Primary structures',
          en: 'Primary structures'
        },
        description: {
          it: 'La caratteristica predominante delle strutture primarie dei velivoli è la lunghezza che può arrivare anche a decine di metri. STG Plus offre una soluzione unica per l’ispezione di tali strutture anche dal  punto di vista della velocità di processamento senza rinunciare ad un’ottima risoluzione dell’immagine,  grazie all’algoritmo Svofir. È già stato realizzato un sistema ct con scan rate 25min/mt , un diametro di acquisizione di 600 mm, e su specifica richiesta del cliente un’ottimizzazione dell’algoritmo svofir per ottenere una risoluzione da 70 µm su volumi parziali.',
          en: 'Length of primary structures of an aircraft can reach tens of meters and that’s its distinctive feature. STG Plus, working with Svofir algorithm, offers a unique solution for the inspection of these structures increasing processing speed and keeping an excellent image resolution. A CT scanner set with: 25min/mt, field of acquisition of 600 mm, specific svofir resolution of 70um (on partial volumes) has been designed on specific client’s requirements.'
        }
      }
    }
  },
  Industry: {
    mainImage: 'industry.png',
    size: 1,
    subsectors: {
      lightweightAlloys: {
        title: {
          it: 'Lightweight alloys',
          en: 'Lightweight alloys'
        },
        description: {
          it: 'Solitamente esiste la necessità di ispezionare grandi volumi in poco tempo e in alcuni casi è molto utile l’automazione del processo. Per incontrare questi requisiti la soluzione più indicata è STG custom line (con architettura gantry) con tavolo ad avanzamento longitudinale. A seguito di un studio preliminare dell’oggetto (forma-dimensioni-materiale) da scansionare si definiscono i parametri di base. Nella nostra esperienza apparati da 225 kV e risoluzioni da 150 micron sono ottimali per l’analisi difettologica di piccole fusioni in lega leggera. Per massimizzare la produttività del sistema viene implementata un’automazione di base per la movimentazione dei pezzi.',
          en: 'When you need to inspect big volumes in a short time, an automation of the process is essential. To meet these requirements, STG custom line (gantry architecture) with longitudinal feed table is certainly the best solution. After a first analysis of shape, size, and material of the object to be scanned, we can define core parameters. Based on our experience, 225 kV equipment and 150 micron resolutions are ideal for defects analysis of lightweight alloys castings. To maximize system productivity, a specific automation of the handling process can be realized.'
        }
      },
      plasticComponents: {
        title: {
          it: 'Plastic components',
          en: 'Plastic components'
        },
        description: {
          it: 'Solitamente esiste la necessità di ispezionare grandi volumi in poco tempo e in alcuni casi è molto utile l’automazione del processo. Solitamente per incontrare questi requisiti la soluzione più indicata è STG custom line dove a seguito di un studio preliminare dell’oggetto da scansionare si definiscono i parametri di base. Nella nostra esperienza con apparati a basso voltaggio (max 100 kV), e utilizzando sensori di grandi dimensioni (fino a 43x43cm) quindi Field of view maggiorati, è possibile massimizzare la produttività del sistema permettendo di scansionare in un singolo ciclo decine di componenti.In aggiunta implementando un’automazione specifica si può velocizzare anche la fase di movimentazione dei pezzi.',
          en: 'When you need to inspect big volumes in a short time, an automation of the process is essential. To meet these requirements, STG custom line is the best solution. After a first analysis of shape, size, and material of the object to be scanned, we can define core parameters. Based on our experience using low energy equipments (100 kV max) and sensors up to 43x43cm an increased FoV can be obtained. This allows to scan dozens of components in one single scanning cycle. Implementing a specific automation, the handling process will speed up.'
        }
      },
      structuralTubes: {
        title: {
          it: 'Carbon fiber Structural Tubes',
          en: 'Carbon fiber Structural Tubes'
        },
        description: {
          it: 'Spesso il limite è legato alla lunghezza del componente. Con la soluzione tecnologica a gantry dello STG mini abbiamo superato questo limite. In relazione al diametro del tubo invece lo STG mini riesce a coprire un range di diametro fino a 380 mm garantendo un field of view da 400 mm Nella nostra esperienza apparati con voltaggio massimo 120 kV e risoluzione fino a 180-200 µm consentono di avere ottimi risultati nel rilevamento dei difetti tipici (carenza di resina, porosità, delaminazioni) del processo Filament Winding. Inoltre con soluzioni software “opzionali” diventano possibili scanning rate 18 min/mt.',
          en: 'Limits are usually related to component’s size. With STG mini’s gantry solution technology, length is no longer a limit. STG mini can cover a diameter range up to 380 mm, keeping a 400 mm field of view. Based on our experience, devices with a maximum voltage of 120 kV and resolutions up to 180-200 µm, allow excellent results in the detection of typical defects such as lack of resin, porosity, delamination of the Filament Winding process. With “optional” software solutions, scanning rates of 18 min/m become possible.'
        }
      }
    }
  },
  Marine: {
    mainImage: 'marine.png',
    size: 0.95,
    subsectors: {
      sailingYacht: {
        title: {
          it: 'Sailing Yacht-Rigging',
          en: 'Sailing Yacht-Rigging'
        },
        description: {
          it: 'Albero, boma, sartiame..rappresentano le più importanti strutture di una barca a vela, tuttavia vengono solitamente ispezionate con semplici tecniche convenzionali , addirittura con il tap test. L’innovazione portata dalla linea stg permette finalmente di eseguire scansioni che raggiungono un livello di dettaglio nemmeno paragonabile a quanto visto fin’ora. con la possibilità di settare un FoV da 850 mm stg-225 hr è l’arma perfetta per rilevare delaminazioni, porosità o disbonding anche su strutture di corda fino a 1000 mm. Ottimizzabile nella linea custom.',
          en: 'Mast, boom, rigging... they represent the most important structures of a sailboat, despect they are still inspected with really basic conventional techniques, like tap test. Innovation brought by the stg line definitively makes possible to perform a huge details level of scanning never seen before. By Setting an 850 mm FoV, the stg-225 hr is your perfect weapon to detect delaminations, porosity or disbondings on structures up to 1000 mm in section. Even more powerful with custom line.'
        }
      },
      motorYacht: {
        title: {
          it: 'Motoryacht-Hull section',
          en: 'Motoryacht-Hull section'
        },
        description: {
          it: 'L’ispezione tomografica di strutture di grandi dimensioni, per es lo scafo di un’ imbarcazione è e rimarrà impossibile. Tuttavia prelevando una piccola porzione di scafo (prese a mare o ritagli di vario tipo) è possibile verificare sia la qualità del laminato, che utilizzare la scansione 3D per poi calibrare gli strumenti ad ultrasuoni, comunemente utilizzati per il controllo NDT degli scafi in composito. STG 225 HR permette di raggiungere risoluzioni fino a 70µm dando la possibilità di analizzare in dettaglio tessuti biassiali,i MAT, altre caratteristiche importanti come gli spessori del laminato, e soprattutto le difettologie tipiche (disbonding,bolle d’aria, ecc). la scansione ct aiuta a mappare, attraverso la rappresentazione/ visualizzazione in pianta, posizione e dimensione di tali difettologie.',
          en: 'With existing technology there is no way to perform a tomographic inspection of huge structures, like the hull of a boat, and it never will. Anyways, by getting a small portion of the hull ( seacocks or offcuts and scraps of any sorts) it is possible to verify both quality of the laminate, and to use 3D scan to calibrate ultrasonic equipments, commonly used for NDT inspection of composite hulls, reaching their full potential. STG 225-HR allows to reach resolutions up to 70µm giving the possibility to analyze in detail Biaxials, MATs, other major features such as thickness of laminate, and above all typical defects like disbondings, voids, etc.. CT scan helps to map, using Top View, the actual position and size of these defects.'
        }
      },
      foils: {
        title: {
          it: 'Foils',
          en: 'Foils'
        },
        description: {
          it: 'Sono diventati componenti fondamentali per le barche che necessitano di performance elevate e grandi velocità. La loro importanza strutturale è fondamentale per la sicurezza: stg plus offre una soluzione impareggiabile per l’ispezione di questi componenti anche nel caso in cui contino diversi metri di lunghezza e alcuni quintali di peso. Settando il field of view fino a 800 mm, con risoluzioni da 300µm è possibile soddisfare ogni esigenza, dalle imbarcazioni più piccole fino ai mega yacht. stg plus è stato impiegato per l’ispezione dei foil di tutte le imbarcazioni che hanno partecipato all’America’s Cup 2021 con il fine di caratterizzare ai massimi livelli la qualità del materiale, diagnosticando ogni più piccola imperfezione.',
          en: 'They became key components for boats that need high performance and great speeds. safety of these structures is essential: stg plus offers unique solutions for the inspection of these components even if they are several meters in length and hundreds of kg in weight. By setting the field of view up to 850 mm, with resolutions of 300µm it is possible to satisfy every need, from the smallest boats to mega yachts. Every foil used in the last AC’ 2021 has been inspected twice using STG-PLUS in Vetorix Lab. The target was to assess any relevant material discontinuities which could affect foil performance during the race.'
        }
      }
    }
  },
  Automotive: {
    mainImage: 'automotive.png',
    size: 0.86,
    subsectors: {
      wheelRim: {
        title: {
          it: 'Ruote in carbonio',
          en: 'Carbon wheel rim'
        },
        description: {
          it: 'L’iter di omologazione di un cerchio destinato all’utilizzo stradale prevede numerosi test per lo più distruttivi. l’esame tomografico può essere di grande aiuto nell’analisi delle condizioni dei componenti prepost test (per es. valutazione della propagazione dei difetti), ma soprattutto risulta molto utile per il monitoraggio del processo di produzione. attraverso l’analisi dei difetti è possibile ottimizzare i parametri di processo riducendo l’incidenza degli scarti. STG permette sia con il modello “Plus” che con la soluzione Custom Line “a piatto rotante”, di rilevare le difettologie tipiche di questi prodotti anche nel caso si presentino in sezioni a geometria complessa come intersezione razza-mozzo e razza-canale. Si possono raggiungere velocità di scansione prossime a 6 ruote/ ora.',
          en: 'Homologation process for a wheel rim “intended” for road use, involves a large number of stressing tests mostly destructive. Tomographic inspection can be really helpful in order to assess structural performances pre/post mechanical tests (e.g. evaluation of defects propagation), but above all can be crucial for manufacturing process monitoring. An analytical defect characterisation may optimize process parameters by reducing the incidence of scraps. STG allows with “Plus” model or even Custom Line “turntable” solution, to detect typical defects of these products even if with complex geometries such as spoke-hub and spokerim intersection. Scanning speeds close to 6 wheels/hour can be achieved.'
        }
      },
      lightweightStructures: {
        title: {
          it: 'Lightweight structures',
          en: 'Lightweight structures'
        },
        description: {
          it: 'solitamente esiste la necessità di ispezionare grandi volumi in poco tempo e in alcuni casi è molto utile l’automazione del processo. Per incontrare questi requisiti la soluzione più indicata è STG custom line (con architettura gantry) con tavolo ad avanzamento longitudinale. A seguito di un studio preliminare dell’oggetto (forma-dimensioni-materiale) da scansionare si definiscono i parametri di base. Nella nostra esperienza apparati da 225 kV e risoluzioni da 150 micron sono ottimali per l’analisi difettologica di piccole fusioni in lega leggera. Per massimizzare la produttività del sistema viene implementata un’automazione di base per la movimentazione dei pezzi.',
          en: 'When you need to inspect big volumes in a short time, an automation of the process is essential. To meet these requirements, STG custom line (gantry architecture) with longitudinal feed table is certainly the best solution. After a first analysis of shape, size, and material of the object to be scanned, we can define core parameters. Based on our experience, 225 kV equipment and 150 micron resolutions are ideal for defects analysis of lightweight alloys castings. To maximize system productivity, a specific automation of the handling process can be realized.'
        }
      },
      structuralComponents: {
        title: {
          it: 'Structural components',
          en: 'Structural components'
        },
        description: {
          it: 'Sospensioni, cerchi, pillars, brancardi…monoscocche complete, questi sono alcuni degli elementi strutturali primari/sicurezza di qualsiasi automobile che ormai da tempo vengono progettati e realizzati in materiale composito. diventa fondamentale da parte delle case costruttrici garantire la sicurezza determinando la qualità di queste strutture. STG 200 hr con field of view prossimi a un metro è in grado di scansionare in meno di un’ora A-B pillar, brancardi o altre strutture che sviluppano anche 2 metri di lunghezza con risoluzioni sub millimetriche.',
          en: "Suspensions, wheel rims, pillars, rockers, monocoques, are just a few primary components of all cars. For those innovative OEM who design and manufacture with carbon fiber as main material, ensure safety of all these structure's now a standard requirement and we know exactly how to do it. STG 225 HR can scan, with almost 1 mt of field of view and in less than one hour, 2m long A-B pillars, rockers and any type of structures with sub millimetric resolutions."
        }
      }
    }
  },
  Motorsport: {
    mainImage: 'motorsport.png',
    size: 0.92,
    subsectors: {
      singleSeatMonocoque: {
        title: {
          it: 'Single seat monocoque',
          en: 'Single seat monocoque'
        },
        description: {
          it: 'Una macchina da F1 è la massima espressione di ingegneria e tecnologia applicata ad un mezzo da corsa.Ogni singolo componente è fondamentale..non esistono accessori. Vetorix ha realizzato un sistema che permette di scansionare in poche ore l’intera monoscocca. STG 225 Hr infatti rende possibile tale ispezione con livelli di risoluzione di circa 350µm, inoltre l’algoritmo svofir consente di raggiungere localmente risoluzioni fino ai 70µm.',
          en: 'F1 car is for sure the highest expression of engineering and technology in the racing world. Every single component is functional. there are no accessories. Vetorix created a system able to scan the entire monocoque in only few hours. STG 225 Hr makes this inspection possible with resolution levels of about 350µm, moreover svofir algorithm allows to locally reach even better resolutions (70µm).'
        }
      },
      racingyMotorbike: {
        title: {
          it: 'Carbon fibre swingarm',
          en: 'Carbon fibre swingarm'
        },
        description: {
          it: 'Come tutti i componenti racing ad alte prestazioni, anche il forcellone realizzato in carbonio è diventato una scelta obbligata per tutti i team di MotoGP. la riduzione di peso e di spessore del laminato rischia però di comprometterne la rigidezza e quindi si rende necessario incollare numerose paratie interne di rinforzo. L’ispezione di tali incollaggi è fondamentale per garantire la sicurezza di questo elemento chiave della ciclistica. STG Plus è un sistema rivoluzionario per eseguire l’ispezione in dettaglio di tutti gli incollaggi delle paratie interne e dei vari inserti in lega in meno di un’ora con ottimi livelli di risoluzione, infatti da anni viene scelto come strumento primario di diagnosi da molti team MotoGP.',
          en: 'Like all high-performance racing components, carbon fiber swingarm is now a must for all MotoGP teams but weight and thickness reduction of the laminate risks to affect its stiffness so it is necessary to add lots of internal reinforcement bulkheads. The inspection of bulkheads bondings becomes essential to guarantee safety of this primary component . STG Plus is our revolutionary system to perform detailed inspection of all bondings of internal bulkheads and alloy inserts in less than an hour with the finest resolution levels, that’s why many MotoGP teams are choosing STG as primary diagnostic tool for so long.'
        }
      },
      racingComponents: {
        title: {
          it: 'Racing components',
          en: 'Racing components'
        },
        description: {
          it: "La passione che lega da tanti anni Vetorix al mondo Motorsport e l' esperienza maturata ci ha permesso di sviluppare processi di ispezione e monitoraggio via via sempre più raffinati ed efficaci. L’ultimo step evolutivo è sicuramente rappresentato dalla piattaforma tomografica STG, che in tutte le declinazioni previste nei modelli Custom-line, soddisfa i requisiti più stringenti dei vari team. Risoluzioni da 70 a 500µm, volumi di scansione diam. 200 - 850 mm e potenze da 80 kV sono solo alcuni dei parametri da studiare insieme al cliente per raggiungere i suoi obiettivi.",
          en: 'For so many years great passion links Vetorix to MotoGP world, and with all this experience gained, we developed increasingly refined effective inspections and monitoring processes. Our last evolution step is the STG tomographic platform, which in all its declinations of Custom-line models, meets the highest racing teams requirements. Resolutions from 70 to 500µm, scan volumes diam. 200 - 850 mm and voltage starting from 80 kV are just some of the parameters we study together with customers to achieve their goals.'
        }
      }
    }
  }
}
