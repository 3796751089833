<template>
  <div class="container contacts-description">
    <div class="w-70">
      <SmallTitle
        alignment="left"
        data-scroll
        data-scroll-offset="40%, 40%"
        data-scroll-call="line-by-line"
      >
        <div v-force-i18n="{ locale: $i18n.locale, message: $t('title') }">
          {{ $t('title') }}
        </div>
      </SmallTitle>
      <SmallText
        alignment="left"
        data-scroll
        data-scroll-offset="40%, 40%"
        data-scroll-call="line-by-line"
      >
        <div v-force-i18n="{ locale: $i18n.locale, message: $t('text') }">
          {{ $t('text') }}
        </div>
      </SmallText>
    </div>
  </div>
</template>

<script>
import SmallTitle from '@/components/Typography/SmallTitle'
import SmallText from '@/components/Typography/SmallText'

export default {
  i18n: {
    messages: {
      it: {
        title: 'Ti abbiamo incuriosito? Ora lasciati stupire!',
        text: 'Rispondi a queste semplici domande e ti contatteremo per una consulenza personalizzata.'
      },
      en: {
        title: 'Are you interested in? Wanna talk?',
        text: 'Answer these simple questions and we will contact you for tailored consulting.'
      }
    }
  },
  components: {
    SmallTitle,
    SmallText
  }
}
</script>

<style lang="scss" scoped>
.contacts-description {
  margin-top: 5.56vw;
  .w-70 {
    margin-bottom: 7.68vw;
    @media all and (min-width: 1024px) {
      width: 70%;
    }
  }
}
</style>
