<template>
  <button :class="type">
    <slot />
    <div class="blurred">
      <slot />
    </div>
    <div class="underline">
      <span /><span />
    </div>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: () => 'default',
      validator: value => ['default', 'small']
    }
  }
}
</script>
<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;
@use '~@/assets/utils/font-size' as fz;

button {
  appearance: none;
  background: none;
  outline: none;
  border: none;
  color: c.$main;
  --fw: 600;
  cursor: pointer;
  padding: 0;
  @include fz.display(21, 24);
  position: relative;
  margin-top: 2.5rem;
  @media all and (min-width: 1024px) {
    margin-top: 4.1875rem;
  }
  &:focus {
    outline: none;
  }
  .blurred {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    width: 100%;
    transition: filter 0.6s e.$in-out, opacity 0.6s e.$in-out;
  }
  .underline {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      width: 50%;
      height: 2px;
      background-color: c.$main;
      transition: transform 0.6s e.$in-out;
      &:first-child {
        transform-origin: right;
      }
      &:last-child {
        transform-origin: left;
      }
    }
  }
  &:hover {
    .blurred {
      filter: blur(10px);
      opacity: 1;
    }
    .line {
      span {
        transform: scaleX(0);
        &:first-child {
          transform-origin: left;
        }
        &:last-child {
          transform-origin: right;
        }
      }
    }
  }
  &.small {
    @include fz.display(14, 16);
    color: #fff;
    .underline {
      display: none;
    }
  }
}

@keyframes blurred {
  0%,
  100% {
    opacity: 0;
    filter: blur(0)
  }
  50% {
    opacity: 1;
    filter: blur(10px);
  }
}
</style>
