<template>
  <div class="contacts-step__area">
    <div class="buttons">
      <MainButton
        v-for="a of Object.keys(areas)"
        :key="a"
        ref="areasButtons"
        class="button"
        :status="a === area ? 'selected' : 'active'"
        @click.native="handleAreasButtonsClick(a)"
      >
        {{ a }}
      </MainButton>
    </div>
    <transition
      name="slide"
      mode="out-in"
    >
      <div v-if="area">
        <transition
          name="slide"
          mode="out-in"
        >
          <div
            :key="area"
            class="buttons"
          >
            <MainButton
              v-for="s of Object.keys(areas[area].subsectors)"
              :key="s"
              class="button"
              :status="s === subsector ? 'selected' : 'active'"
              @click.native="handleSubsectorsButtonsClick(s)"
            >
              {{ areas[area].subsectors[s].title[$i18n.locale] }}
            </MainButton>
          </div>
        </transition>
        <transition
          name="subsectors"
          mode="out-in"
        >
          <div
            v-if="subsectorHl"
            :key="subsectorHl"
            ref="subsectionDescription"
            class="subsector-description"
          >
            <SmallText
              alignement="left"
            >
              {{ areas[area].subsectors[subsectorHl].description[$i18n.locale] }}
            </SmallText>
            <div class="subsector-description__button">
              <ArrowButton
                side="next"
                @click.native="$emit('go-to-form')"
              >
                {{ $t('next') }}
              </ArrowButton>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import areas from '@/dataset/areas'
import SmallText from '@/components/Typography/SmallText'
import MainButton from '@/components/MainButton'
import ArrowButton from '@/components/ArrowButton'
import { mapGetters } from 'vuex'

export default {
  i18n: {
    messages: {
      it: {
        next: 'Avanti'
      },
      en: {
        next: 'Next'
      }
    }
  },
  components: {
    MainButton,
    SmallText,
    ArrowButton
  },
  props: {
    area: {
      type: String,
      required: true
    },
    subsector: {
      type: String,
      required: true
    }
  },
  data: () => ({
    areas,
    btnReduced: false,
    subsectorHl: null
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  methods: {
    handleAreasButtonsClick (area) {
      this.$emit('update-value', { area })
      this.subsectorHl = null
      if (this.btnReduced) return
      this.$nextTick(() => {
        this.btnReduced = true
        this.$refs.areasButtons.forEach((btn) => {
          btn.reduce()
        })
      })
    },
    handleSubsectorsButtonsClick (subsector) {
      this.subsectorHl = subsector
      this.$emit('update-value', { subsector })
      this.$nextTick(() => {
        this.scroller().scrollTo(this.$refs.subsectionDescription, { duration: 200, offset: -200 })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/easing' as e;

.contacts-step__area {
  .buttons {
    display: flex;
    flex-wrap: wrap;
    margin: 2.52vw 0;
    position: relative;
    justify-content: center;
    align-items: center;
    @media all and (min-width: 768px) {
      justify-content: center;
    }
    ::v-deep .button {
      margin: 1rem 1rem 1rem 0;
      @media all and (min-width: 768px) {
        margin: 1rem 1.75rem;
      }
    }
  }
}

.subsector-description {
  display: grid;
  grid-template-columns: 1fr;
  @media all and (min-width: 768px) {
    align-items: center;
    grid-template-columns: 1.2fr 0.8fr;
    &__button {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
