<template>
  <div class="container">
    <div
      class="video-reel"
      :class="{ 'show-play-button': showPlayButton }"
      data-scroll
      data-scroll-call="fade-in"
      data-scroll-repeat
      data-scroll-offset="20%, 20%"
    >
      <div class="wrapper">
        <transition name="play-button">
          <div
            v-if="showPlayButton"
            class="play-button"
            @click="playVideo"
          >
            <div class="play-button__bg" />
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 44 52"
            ><path
              d="M44 26L.5 51.115V.885L44 26z"
              fill="#fff"
            /></svg>
          </div>
        </transition>
        <transition name="fallback">
          <img
            v-if="!videoLoaded"
            src="/images/video-fallback.jpg"
            data-scroll
            data-scroll-speed="-2"
          >
        </transition>
        <div
          class="iframe-container"
          :class="{ show: videoLoaded }"
        >
          <youtube
            ref="youtube"
            video-id="lR1PyCeTqBI"
            :fit-parent="true"
            @playing="playing"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showPlayButton: true,
    videoLoaded: false
  }),
  computed: {
    player: {
      get () {
        return this.$refs.youtube.player
      }
    }
  },
  methods: {
    async playVideo () {
      await this.player.playVideo()
      this.videoLoaded = true
      this.showPlayButton = false
    },
    playing () {
      this.showPlayButton = false
    }
    // paused () {
    //   this.showPlayButton = true
    // }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;

.video-reel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  cursor: pointer;
  pointer-events: none;
  overflow: hidden;
  .wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .play-button {
    width: 9.875vw;
    height: 9.875vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    pointer-events: auto;
    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: c.$main;
      transition: transform 1.2s e.$out, box-shadow 1.2s e.$out;
    }
    svg {
      position: relative;
      width: 36.7%;
      padding-left: 10%;
      transition: transform 1.2s e.$out, opacity 1.2s e.$out;
    }
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    object-fit: cover;
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: c.$bg;
    opacity: 0;
    z-index: 2;
    transition: opacity 1.2s e.$out;
  }
  &.show-play-button {
    &::after {
      opacity: 0.4;
    }
  }
  &:hover {
    .play-button__bg {
      transform: scale(1.1);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    svg {
      transform: scale(0.9);
      opacity: 0.8;
    }
    &::after {
      opacity: 0.6;
    }
  }
  .iframe-container {
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: inset(100% 0% 0% 0%);
    z-index: 9;
    pointer-events: auto;
    transition: clip-path 0.8s e.$out;
    &.show {
      clip-path: inset(0);
    }
    ::v-deep iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.play-button-enter-active,
.play-button-leave-active {
  transition: all 1.2s e.$out;
  .play-button__bg {
    transition-delay: 0.2s;
  }
}

.play-button-enter,
.play-button-leave-to {
  .play-button__bg {
    transform: scale(0);
    opacity: 0;
  }
  svg {
    transform: scale(0);
    opacity: 0;
  }
}

.fallback-enter-active,
.fallback-leave-active {
  transition: all 0.8s e.$out;
}

.fallback-enter,
.fallback-leave-to {
  transform: scale(1.2);
}
</style>
