export default {
  introduction: {
    sections: [
      'intro-main',
      'intro-maintext',
      'intro-payoff',
      'intro-data'
    ],
    totalFrames: 400,
    initialFrame: 205,
    autoplay: false,
    videoDuration: 8,
    videoEase: 'power4.inOut'
  },
  ring_rotation: {
    totalFrames: 250,
    initialFrame: 250,
    autoplay: true,
    videoDuration: 6,
    videoEase: 'power4.in'
  }
}
