<template>
  <div
    id="technical-data"
    class="container"
    data-scroll
    data-scroll-repeat
    data-scroll-call="slide-in"
    data-scroll-offset="40%, 40%"
  >
    <div
      class="content"
      data-scroll
      data-scroll-sticky
      data-scroll-target="#technical-data"
    >
      <div class="data">
        <div
          v-for="number of progressiveNumbers"
          :key="number.id"
        >
          <VerySmallTitle class="title">
            {{ $t(`${number.id}.title`) }}
          </VerySmallTitle>
          <div class="number">
            <ProgressiveNumber
              :id="number.id"
              ref="progressive"
              :from="number.from"
              :to="number.to"
              :reverse="number.reverse"
              :unit="$t(`${number.id}.unit`) || ''"
              animation-type="progressiveByScroll"
              section-watcher="technical-data"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerySmallTitle from '@/components/Typography/VerySmallTitle'
import ProgressiveNumber from '@/components/ProgressiveNumber'

export default {
  i18n: {
    messages: {
      it: {
        rotation: {
          title: 'Velocità di rotazione',
          unit: 's/giro'
        },
        scannedImages: {
          title: 'Immagini acquisite',
          unit: ' '
        }
      },
      en: {
        rotation: {
          title: 'Rotation speed',
          unit: 's/rot'
        },
        scannedImages: {
          title: 'Acquisition rate',
          unit: ' '
        }
      }
    }
  },
  components: {
    VerySmallTitle,
    ProgressiveNumber
  },
  data: () => ({
    progressiveNumbers: [
      {
        id: 'rotation',
        from: 150,
        to: 35,
        reverse: true
      },
      {
        id: 'scannedImages',
        from: 720,
        to: 2880,
        reverse: false
      }
    ]
  }),
  methods: {
    play () {
      this.$refs.progressive
        .map((p) => p.play())
    }
  }
}
</script>

<style lang="scss" scoped>
#technical-data {
  height: 200vh;
  display: flex;
  justify-content: flex-end;
  .content {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @media all and (min-width: 768px) {
      width: 30%;
    }
    .data {
      display: flex;
      top: -20vh;
      position: relative;
      @media all and (min-width: 1024px) {
        top: 0;
        flex-direction: column;
      }
      & > div {
        margin-top: 3.75rem;
      }
    }
  }
  .title {
    max-width: 70%;
  }
}
</style>
