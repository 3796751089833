<template>
  <div v-if="scroller">
    <div
      class="table-card"
      data-scroll
      data-scroll-call="slide-in"
      data-scroll-offset="40%"
    >
      <Accordion :disable="!scroller().scroll.isMobile && !scroller().scroll.isTablet">
        <template v-slot:head>
          <SmallTitle alignment="left">
            <slot />
          </SmallTitle>
        </template>
        <template v-slot:body>
          <div class="elements">
            <div
              v-for="(el, i) of elements"
              :key="i"
            >
              <span><svg
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 35 27"
              ><path
                d="M2 13.5L12.5 25l20-23"
                stroke="#61F48C"
                stroke-width="4"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg></span>
              <p v-html="el" />
            </div>
          </div>
        </template>
      </Accordion>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SmallTitle from '@/components/Typography/SmallTitle'
import Accordion from '@palazzinacreativa/vue-accordion'

export default {
  components: {
    SmallTitle,
    Accordion
  },
  props: {
    elements: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/utils/font-size' as fz;

.table-card {
  position: relative;
  width: 100%;
  background-color: #303030;
  border-radius: 2rem;
  padding: 1.875rem 1.125rem;
  @media all and (min-width: 768px) {
    padding: 4.25rem 3.625rem;
  }
  .elements {
    margin-top: 2.5rem;
    @media all and (min-width: 768px) {
      margin-top: 5.375rem;
    }
    & > div {
      display: flex;
      align-items: flex-start;
      span {
        min-width: 2rem;
        max-width: 2rem;
        svg {
          width: 100%;
        }
      }
      p {
        margin-left: 1.125rem;
        @include fz.text(18, 24);
        color: c.$gray;
        margin-bottom: 1.875rem;
        strong {
          color: #fff;
        }
      }
      &:last-child {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
