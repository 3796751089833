import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import store from '@/store'

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollToPlugin)

const scrollToSection = (el, progress) => {
  if (store.getters.scrollDirection === 'up' || store.getters.scroller().el.classList.contains('can-t-stop')) return
  const currentSection = el.closest('[data-scroll-section]')
  const target = progress > 0.5
    ? currentSection.nextElementSibling
    : currentSection.previousElementSibling

  const duration = Math.round(target.offsetTop - store.getters.scrollPosition) * 1.2
  // const offset = store.getters.scrollDirection === 'down'
  //   ? 0
  //   : -window.innerHeight
  const offset = 0
  store.getters.scroller().scrollTo(target, {
    offset,
    duration,
    easing: [0, 0, 1, 1]
  })
}

export default {
  'line-by-line': {
    enter: (el) => {
      const toSplit = el.querySelectorAll(':scope > *:not(br):not(strong)').length ? el.querySelectorAll('*:not(br):not(strong)') : el
      let lines = el.querySelectorAll('.line')
      if (!lines.length) {
        const split = new SplitText(toSplit, { type: 'lines', linesClass: 'line' })
        lines = split.lines
        lines.forEach((el) => {
          if (el.parentNode.tagName !== 'STRONG') return
          const content = el.innerHTML
          el.parentNode.innerHTML = content
        })
      }
      gsap.set(el, { opacity: 1 })
      gsap.set(lines, { y: 20, opacity: 0, transformOrigin: 'top left', skewY: 4 })
      gsap.to(lines, {
        y: 0,
        opacity: 1,
        skewY: 0,
        duration: 1.8,
        ease: 'expo.out',
        stagger: {
          each: 0.15,
          ease: 'power4.inOut'
        }
      })
    },
    exit: (el, progress) => {
      gsap.timeline()
        .add(() => {
          if (typeof el.getAttribute('data-scrolltosection')?.length !== 'undefined') scrollToSection(el, progress)
        })
        .to(el.querySelectorAll('.line'), {
          opacity: 0,
          y: -20,
          duration: 1.2,
          ease: 'expo.out',
          stagger: {
            each: 0.15,
            ease: 'power4.inOut'
          }
        })
    }
  },
  'char-by-char': {
    enter: (el) => {
      const toSplit = el.querySelectorAll(':scope > *:not(br)').length ? el.querySelectorAll(':scope > *:not(br)') : el
      let chars = el.querySelectorAll('.char')
      if (!chars.length) {
        const split = new SplitText(toSplit, { type: 'chars', charsClass: 'char' })
        chars = split.chars
      }
      gsap.set(el, { opacity: 1 })
      gsap.set(chars, { opacity: 0, y: 18, transformOrigin: 'top left', skewY: 10 })
      gsap.to(chars, {
        y: 0,
        opacity: 1,
        skewY: 0,
        duration: 0.8,
        ease: 'expo.out',
        stagger: 0.03
      })
    },
    exit: (el, progress) => {
      gsap.timeline()
        .add(() => {
          if (typeof el.getAttribute('data-scrolltosection')?.length !== 'undefined') scrollToSection(el, progress)
        })
        .to(el, { opacity: 0, duration: 1.2, ease: 'expo.out' })
    }
  },
  'slide-in': {
    enter: (el) => {
      if (el.dataset.scrollOnlyexit) return
      gsap.to(el, { y: 0, opacity: 1, duration: 2.2, ease: 'expo.out' })
    },
    exit: (el, progress) => {
      gsap.timeline()
        .add(() => {
          if (typeof el.getAttribute('data-scrolltosection')?.length !== 'undefined') scrollToSection(el, progress)
        })
        .to(el, { y: -50, opacity: 0, duration: 1.2, ease: 'expo.out' })
    }
  },
  'fade-in': {
    enter: (el) => {
      if (el.dataset.scrollOnlyexit) return
      gsap.to(el, { opacity: 1, duration: 2.2, ease: 'expo.out' })
    },
    exit: (el, progress) => {
      gsap.timeline()
        .add(() => {
          if (typeof el.getAttribute('data-scrolltosection')?.length !== 'undefined') scrollToSection(el, progress)
        })
        .to(el, { opacity: 0, duration: 1.2, ease: 'expo.out' })
    }
  },
  progressive (el) {
    const digits = el.querySelectorAll('.digit')

    const delays = [0.1, 0.2, 0.3, 0.4, 0.5]
    digits.forEach((node, i) => {
      const end = node.dataset.digitEnd
      const items = node.querySelectorAll('.digit__item')

      const exactItems = []
      items.forEach((item) => {
        if (item.dataset.digit !== end) return
        exactItems.push(item)
      })
      const randomItem = exactItems[Math.floor(Math.random() * exactItems.length - 1) + 1]
      const delay = delays[Math.floor(Math.random() * delays.length)]
      gsap.to(node, { scrollTo: randomItem, duration: 6, delay, ease: 'expo.inOut' })
    })
  },
  sticky: {
    enter (el) {
      const mainContent = el.querySelector('.main-content')
      mainContent.style.position = 'fixed'
    },
    exit (el) {
      const mainContent = el.querySelector('.main-content')
      mainContent.style.position = 'relative'
    }
  }
}
