<template>
  <p :class="[ alignment, color ]">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    alignment: {
      type: String,
      default: () => 'center',
      validator: value => ['left', 'center', 'right']
    },
    color: {
      type: String,
      default: () => 'white',
      validator: value => ['white', 'gray']
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/utils/font-size' as fz;

p {
  line-height: 1.43;
  --fw: 600;
  @include fz.display(21, 28);
  letter-spacing: -1.5px;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.white {
    color: #fff;
  }
  &.gray {
    color: c.$gray;
  }
}
</style>
