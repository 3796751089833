<template>
  <div class="no-limits-container">
    <StickySection
      :vh="16"
      section-id="nolimits"
    >
      <NoLimitsTitle
        :area="area"
        :scroll-direction="scrollDirection"
      />
      <MachineTrack @area-switch="handleAreaSwitch" />
      <Software
        :current-software="currentSoftware"
        @area-switch="handleAreaSwitch"
      />
    </StickySection>
    <SoftwareControls @software-change="handleSoftwareChange" />
    <SoftwareDescription />
  </div>
</template>

<script>
import StickySection from '@/components/StickySection'
import NoLimitsTitle from './NoLimitsTitle'
import MachineTrack from './MachineTrack'
import Software from './Software'
import SoftwareControls from './SoftwareControls'
import SoftwareDescription from './SoftwareDescription'

export default {
  components: {
    NoLimitsTitle,
    MachineTrack,
    Software,
    SoftwareControls,
    StickySection,
    SoftwareDescription
  },
  data: () => ({
    area: false,
    scrollDirection: 'next',
    currentSoftware: {
      acquisitionField: 100,
      res: 'hi'
    }
  }),
  methods: {
    handleAreaSwitch ({ area = '', scrollDirection = 'next' }) {
      this.area = area
      this.scrollDirection = scrollDirection
    },
    handleSoftwareChange (payload) {
      this.currentSoftware = Object.assign(this.currentSoftware, payload, {})
    }
  }
}
</script>

<style lang="scss" scoped>
.no-limits-container {
  position: relative;
  display: flex;
  flex-direction: column;
  ::v-deep .main-content {
    width: 100%;
    max-width: none;
  }
}
#no-limits {
  height: 1600vh;
  .section {
    position: relative;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
</style>
