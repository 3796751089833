<template>
  <div
    class="contacts-step"
  >
    <transition
      name="slide"
      mode="out-in"
    >
      <div :key="currentStep">
        <div
          v-if="currentStep !== 'complete'"
          class="title"
        >
          <SmallTitle v-if="currentStep === 'area'">
            {{ $t('areaTitle') }}
          </SmallTitle>
          <div v-else>
            <SmallTitle>
              {{ $t('titleLast') }}
            </SmallTitle>
            <SmallText
              alignment="center"
              :no-margin="true"
            >
              {{ $t('contacts') }}
            </SmallText>
          </div>
        </div>
        <div v-if="currentStep === 'area'">
          <Area
            :area="values.area"
            :subsector="values.subsector"
            @update-value="updateValue"
            @go-to-form="currentStep = 'form'"
          />
        </div>
        <div v-else-if="currentStep === 'form'">
          <StepForm
            :values="{ name: values.name, email: values.email, message: values.message }"
            @update-form="handleUpdateForm"
            @back-to-area="currentStep = 'area'"
            @form-complete="sendForm"
          />
        </div>
        <div
          v-else-if="currentStep === 'complete'"
          class="form-complete"
        >
          <div class="form-complete__title">
            {{ $t('sent') }}
          </div>
          <div class="form-complete__desc">
            {{ $t('sentText') }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SmallTitle from '@/components/Typography/SmallTitle'
import SmallText from '@/components/Typography/SmallText'
import Area from './Area'
import StepForm from './StepForm'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  i18n: {
    messages: {
      it: {
        areaTitle: 'In che tipo di settore operi?',
        titleLast: 'Siamo quasi alla fine',
        contacts: 'Inserisci i tuoi contatti per ricevere un’offerta su misura per te',
        sent: 'Richiesta inviata!',
        sentText: 'La tua richiesta è stata inviata con successo, verrai ricontattato quanto prima.'
      },
      en: {
        areaTitle: 'What kind of industry do you operate in?',
        titleLast: 'We are almost at the end',
        contacts: 'Enter your contacts to receive an offer tailored to you',
        sent: 'Request sent!',
        sentText: 'Your request has been sent successfully, you will be contacted as soon as possible.'
      }
    }
  },
  components: {
    SmallTitle,
    SmallText,
    Area,
    StepForm
  },
  data: () => ({
    currentStep: 'area',
    values: {
      area: '',
      subsector: '',
      name: '',
      email: '',
      message: ''
    }
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  watch: {
    values: {
      handler () {
        const interval = setInterval(() => {
          this.scroller().scroll.update()
        }, 20)

        setTimeout(() => {
          clearInterval(interval)
        }, 500)
      },
      deep: true
    }
  },
  methods: {
    updateValue (payload) {
      this.values = Object.assign(this.values, payload, {})
    },
    handleUpdateForm ({ value, field }) {
      this.values = Object.assign(this.values, { [field]: value }, {})
    },
    encode (data) {
      return Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&')
    },
    async sendForm () {
      const axiosConfig = {
        header: { 'Content-Type': 'application/x-www-form-urlencoded' }
      }
      try {
        await axios.post(
          '/',
          this.encode({
            'form-name': 'contacts',
            area: this.values.area,
            subsector: this.values.subsector,
            name: this.values.name,
            email: this.values.email,
            message: this.values.message
          }),
          axiosConfig
        )
        this.currentStep = 'complete'
        this.$gtm.trackEvent({
          event: 'contacts_form',
          category: 'Form',
          action: 'SendForm'
        })
        // this.submitted = true
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/easing' as e;
@use '~@/assets/styles/colors' as c;

.contacts-step {
  .title {
    margin: 2.52vw 0;
    position: relative;
    display: inline-block;
    width: 100%;
  }
  .buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 2.52vw 0;
    position: relative;
    ::v-deep .button {
      margin: 1rem 1rem 1rem 0;
      @media all and (min-width: 768px) {
        margin: 1rem 1.75rem;
      }
    }
  }
}

.subsector-description {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  align-items: center;
  &__button {
    display: flex;
    justify-content: center;
  }
}

.form-complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  &__title {
    text-align: center;
    font-size: 1.76vw;
    color: c.$main;
    --fw: 700;
    margin-bottom: 1rem;
  }
  &__desc {
    text-align: center;
    font-size: 1.01vw;
    color: c.$gray;
  }
}
</style>
