<template>
  <div
    ref="inputWrapper"
    class="contacts-step__form container--small"
  >
    <div class="input">
      <transition
        name="slide"
        mode="out-in"
      >
        <div :key="currentStep">
          <ValidationProvider
            v-slot="{ errors }"
            ref="provider"
            class="input-wrapper"
            :rules="fields[currentStep].rules"
          >
            <label>
              {{ $t(`${fields[currentStep].name}.label`) }}
            </label>
            <input
              v-if="fields[currentStep].type === 'text'"
              type="text"
              :name="fields[currentStep].name"
              :value="values[fields[currentStep].name]"
              :placeholder="$t(`${fields[currentStep].name}.placeholder`)"
              @input="(e) => handleChange(e, fields[currentStep].name)"
            >
            <textarea
              v-else-if="fields[currentStep].type === 'textarea'"
              ref="textarea"
              :name="fields[currentStep].name"
              :value="values[fields[currentStep].name]"
              :placeholder="$t(`${fields[currentStep].name}.placeholder`)"
              @input="(e) => handleTextareaInput(e, fields[currentStep].name)"
            />
            <span class="underline" />
            <p
              v-if="errors"
              class="error"
            >
              {{ $t(errors[0]) }}
            </p>
          </ValidationProvider>
          <div class="buttons">
            <ArrowButton
              side="prev"
              @click.native="goBack"
            >
              {{ $t('back') }}
            </ArrowButton>
            <ArrowButton
              side="next"
              @click.native="goForward"
            >
              {{ $t('next') }}
            </ArrowButton>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'

import { ValidationProvider, extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import { mapGetters } from 'vuex'

extend('email', {
  ...email,
  message: 'emailError'
})

extend('required', {
  ...required,
  message: 'required'
})

export default {
  i18n: {
    messages: {
      it: {
        name: {
          label: 'Nome e cognome',
          placeholder: 'Digita qui il tuo nome'
        },
        email: {
          label: 'Indirizzo e-mail',
          placeholder: 'Digita qui la tua e-mail'
        },
        message: {
          label: 'Messaggio',
          placeholder: 'Se hai altro da specificare scrivilo qui'
        },
        emailError: 'Formato email non valido',
        required: 'Il campo è obbligatorio',
        back: 'Indietro',
        next: 'Avanti'
      },
      en: {
        name: {
          label: 'Full name',
          placeholder: 'Insert here your name'
        },
        email: {
          label: 'E-mail address',
          placeholder: 'Insert here your e-mail'
        },
        message: {
          label: 'Message',
          placeholder: 'Message'
        },
        emailError: 'E-mail not valid',
        required: 'The field is required',
        back: 'Back',
        next: 'Next'
      }
    }
  },
  components: {
    ArrowButton,
    ValidationProvider
  },
  props: {
    values: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    currentStep: 0,
    fields: [
      {
        name: 'name',
        type: 'text',
        rules: 'required'
      },
      {
        name: 'email',
        type: 'text',
        rules: 'email|required'
      },
      {
        name: 'message',
        type: 'textarea',
        rules: 'required'
      }
    ]
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  mounted () {
    this.centerInputWrapper()
    if (!this.$refs.textarea) return
    this.$nextTick(() => {
      this.$refs.textarea[0].style.height = 'auto'
      this.$refs.textarea[0].style.height = `${this.$refs.textarea[0].scrollHeight + 2}px`
    })
  },
  methods: {
    autoresize () {
      this.$refs.textarea[0].style.height = 'auto'
      this.$refs.textarea[0].style.height = `${this.$refs.textarea[0].scrollHeight + 2}px`
    },
    handleTextareaInput (e, name) {
      this.handleChange(e, name)
      this.autoresize()
    },
    handleChange (e, field) {
      this.$emit('update-form', { value: e.target.value, field })
    },
    centerInputWrapper () {
      const target = this.$refs.inputWrapper.parentNode.parentNode
      const offset = (window.innerHeight - target.offsetHeight) / -2
      console.log(offset)
      this.scroller().scrollTo(target, { offset })
    },
    goBack () {
      if (this.currentStep === 0) {
        this.$emit('back-to-area')
        return
      }
      this.currentStep--
      this.centerInputWrapper()
    },
    goForward () {
      if (this.currentStep === this.fields.length - 1) {
        this.$emit('form-complete')
        return
      }
      if (!this.values[this.fields[this.currentStep].name]) {
        this.$refs.provider[this.currentStep].validate()
        return
      }
      this.currentStep++
      this.centerInputWrapper()
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-step__form {
  overflow: hidden;
  .buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
}

.input-wrapper {
  position: relative;
  display: block;
}

@keyframes brightness {
  0%,
  100% {
    filter: blur(0)
  }
  50% {
    filter: blur(10px)
  }
}
</style>
