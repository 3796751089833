<template>
  <div
    id="footer"
    class="footer container"
    data-scroll
    data-scroll-call="fade-in"
  >
    <div class="footer__contacts">
      <p>
        Vetorix Engineering S.r.l. Unipersonale <br>
        Via dell’Artigianato, 12 <br>
        30020 Marcon – VE – ITALY
      </p>
    </div>
    <div class="footer__contacts">
      <p class="footer__contacts--link">
        <strong>Tel.:</strong> <a href="tel:+390415951457">+39 041 5951457</a>
      </p>
      <p class="footer__contacts--link">
        <strong>Email:</strong> <a href="mailto:info@vetorixndi.com">info@vetorixndi.com</a>
      </p>
      <SocialContacts :contacts="contacts" />
    </div>
    <div class="footer__fdata">
      <div>P. IVA: 03706440272</div>
      <div>REA: VE-331512</div>
      <div>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
      </div>
    </div>
    <div class="footer__credits">
      Made by <a
        href="https://palazzinacreativa.it/credits"
        target="_blank"
      >PalazzinaCreativa</a>
    </div>
  </div>
</template>

<script>
import SocialContacts from '@palazzinacreativa/social-contacts'

export default {
  components: {
    SocialContacts
  },
  data: () => ({
    contacts: {
      linkedin: 'https://www.linkedin.com/company/vetorix-group/'
    }
  })
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;

.footer {
  background-color: #000;
  margin-top: 6.06vw;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1rem 2rem;
  z-index: 0;
  @media all and (min-width: 768px) {
    padding: 3.83vw 0 1.51vw;
    display: grid;
    grid-row-gap: 3.6vw;
    grid-column-gap: 7.2vw;
    grid-template-columns: repeat(3, 1fr);
  }
  &__contacts {
    margin-top: 4.625rem;
    @media all and (min-width: 768px) {
      margin-top: 0;
    }
    p {
      margin-bottom: 1rem;
      position: relative;
      display: block;
      --fw: 600;
      color: #fff;
      font-size: 1rem;
      line-height: 1.5625;
      strong {
        --fw: 800;
      }
      a {
        text-decoration: underline;
        transition: opacity 0.4s ease;
        color: #fff;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    &--link {
      strong {
        color: #ABABAB;
      }
    }
    ::v-deep .social-links {
      margin-top: 1.25rem;
    }
  }
  &__fdata {
    & > div {
      margin-bottom: 1rem;
      a {
        color: #ababab;
        font-size: 0.75rem;
        transition: opacity 0.4s ease;
        @media all and (min-width: 768px) {
          font-size: 1.2rem;
        }
        &:nth-child(2) {
          margin-left: 2rem;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  &__credits {
    grid-column-end: span 3;
    text-align: center;
    font-size: 1rem;
    line-height: 1.5625;
    a {
      color: #ababab;
      transition: opacity 0.4s ease;
      &:nth-child(2) {
        margin-left: 2rem;
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
</style>
