<template>
  <div class="container software-description">
    <div class="w-70">
      <Title
        data-scroll
        data-scroll-offset="40%, 40%"
        data-scroll-call="line-by-line"
      >
        <div v-force-i18n="{ locale: $i18n.locale, message: $t('title') }">
          {{ $t('title') }}
        </div>
      </Title>
      <SmallText
        alignment="left"
        data-scroll
        data-scroll-offset="40%, 40%"
        data-scroll-call="line-by-line"
      >
        <span
          v-force-i18n="{ locale: $i18n.locale, message: $t('text') }"
          v-html="$t('text')"
        />
      </SmallText>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Typography/Title'
import SmallText from '@/components/Typography/SmallText'

export default {
  i18n: {
    messages: {
      it: {
        title: 'Algoritmo senza eguali',
        text: 'SVoFir, il software di <strong>STG-One</strong> sviluppato interamente nel nostro laboratorio, consente di gestire in maniera ottimale i volumi ricostruiti della struttura in esame. Agisce permettendo all’utente di selezionare volumi parziali da ricostruire in alta risoluzione oppure i volumi totali da ricostruire ad una risoluzione più bassa per contingentare il peso dei file.'
      },
      en: {
        title: 'Unmatched algorithm',
        text: 'SVoFir, the <strong>STG-One</strong> software has been completely developed in our laboratory, allows you to manage reconstructed volumes of the structure under examination at the best. The user can select partial volumes that will be reconstructed in high resolution or that reconstruct the whole volume in a lower resolution in order to reduce the weight of files.'
      }
    }
  },
  components: {
    Title,
    SmallText
  }
}
</script>

<style lang="scss" scoped>
.software-description {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .w-70 {
    margin-bottom: 7.68vw;
    @media all and (min-width: 1024px) {
      width: 70%;
    }
  }
}
</style>
