<template>
  <div class="container comparison">
    <div class="tables">
      <TableCard :elements="$t('commonCtSystem')">
        {{ $t('titleCommonCtSystems') }}
      </TableCard>
      <TableCard :elements="$t('vetorixCtSystem')">
        {{ $t('titleVetorixCtSystems') }}
      </TableCard>
    </div>
  </div>
</template>

<script>
import TableCard from '@/components/TableCard'

export default {
  components: {
    TableCard
  },
  i18n: {
    messages: {
      it: {
        titleCommonCtSystems: 'Perché comprare un sistema TC?',
        commonCtSystem: [
          'Miglioramento della <strong>qualità</strong> globale del prodotto;',
          'Incremento della probabilità di <strong>rilevamento problematiche</strong>;',
          'Diagnosi precoce di <strong>difetti di manifattura</strong>;',
          'Aumento dell’<strong>accuratezza del rilevamento problematiche</strong>;',
          'Basso <strong>costo</strong> di ispezione per unità;',
          'Basse <strong>tempistiche</strong> di ispezione per unità;',
          'Possibilità di esaminare il <strong>volume nella sua interezza</strong>;',
          '<strong>Validazione geometrica e controllo della qualità</strong> (spessore, raggio, angoli, dimensioni);'
        ],
        titleVetorixCtSystems: 'Perché comprare un sistema TC da Vetorix?',
        vetorixCtSystem: [
          '<strong>Risoluzione corretta</strong> (range di risoluzione dinamica da 60μm a 500μm) per un’ampia gamma di strutture;',
          'Costruito appositamente per <strong>NDI</strong>;',
          '<strong>Attrezzatura personalizzata</strong> per ogni esigenza NDI (risoluzione, grandezza delle parti, velocità di analisi, volume dei dati, personalizzazione dei riscontri automatizzati di dati, intelligenza artificiale, software personalizzato per la ricostruzione del volume - importante per le tempistiche di scannerizzazione);',
          'Requisiti di scansione automatizzata (<strong>riduzione al minimo dell’intervento umano</strong>)',
          'Siamo ispettori specializzati NDI. Comprare da Vetorix significa comprare anche le nostre competenze nell’<strong>ambito Non Destructive Inspection</strong> (esperienza, innovazione, competenze tecniche, formazione) di alto livello.'
        ]
      },
      en: {
        titleCommonCtSystems: 'Why Buy a CT System?',
        commonCtSystem: [
          'Improve Overall Product <strong>Quality</strong>',
          'Increase Probability of <strong>Detection of Issues</strong>',
          'Early Detection of <strong>Manufacturing Issues</strong>',
          'Increase <strong>Detection Accuracy</strong>',
          'Low per unit inspection <strong>cost</strong>',
          'Low per unit inspection <strong>time</strong>',
          'Possibility to Inspect the <strong>Whole Volume</strong>',
          '<strong>Geometric Validation & Quality Control</strong> (Thickness, Radius, Angles, Dimensions)'
        ],
        titleVetorixCtSystems: 'Why Buy a CT System from Vetorix?',
        vetorixCtSystem: [
          '<strong>Right Resolution</strong> (Dynamic Resolution range from 60μm to 500μm) for a wide range of structures',
          'Purpose Built for <strong>NDI</strong>',
          '<strong>Custom Equipment</strong> for your NDI needs (Resolution, Size of Parts, Speed of Inspection, “data volume”, customize automated data findings - AI, Customized software for volume reconstruction (Matters because of scan time)',
          'Automated Scanning Requirements (<strong>minimize human intervention</strong>)',
          'We are NDI specialist inspectors. If you buy from Vetorix you are also buying the <strong>expertise in NDI</strong> (Know how, Experience, Innovation, Specification, Training) at top level.'
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.comparison {
  .tables {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2.25rem;
    @media all and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
