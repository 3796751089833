<template>
  <div>
    <div
      class="toggle"
      :class="[status, { selected: value }]"
    >
      <span class="toggle__bg" />
      <div />
    </div>
    <div
      class="toggle__labels"
      :class="status"
    >
      <span :class="{ active: !value }">{{ labels[0] }}</span>
      <span>/</span>
      <span :class="{ active: value }">{{ labels[1] }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: () => 'active',
      validator: value => ['active', 'disabled']
    },
    value: {
      type: Boolean,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;
@use '~@/assets/utils/font-size' as fz;

.toggle {
  width: 4.6875rem;
  height: 2.5rem;
  cursor: pointer;
  position: relative;
  @media all and (min-width: 1600px) {
    width: 3.78vw;
    height: 2.02vw;
  }
  &__labels {
    --fw: 600;
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    @include fz.text(16, 22);
    span {
      color: c.$gray;
      transition: color 0.4s e.$out;
      &.active {
        color: c.$main;
      }
    }
  }
  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    background-color: #474646;
    transition: opacity 1.2s e.$out;
  }
  div {
    width: 2rem;
    height: 2rem;
    top: 0.25rem;
    left: 0.25rem;
    border-radius: 50%;
    background-color: c.$main;
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transition: transform 0.6s e.$out, box-shadow 1.2s e.$out;
    @media all and (min-width: 1600px) {
      width: 1.61vw;
      height: 1.61vw;
      top: 0.2vw;
      left: 0.2vw;
    }
  }
  &:hover {
    .toggle__bg {
      opacity: 0.8;
    }
    div {
      transform: scale(0.8);
      box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.5);
    }
    &.selected {
      div {
        transform: scale(0.8) translateX(100%);
      }
    }
  }
  &.selected {
    div {
      transform: translateX(100%);
    }
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}
</style>
