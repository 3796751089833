<template>
  <div
    class="data-collection"
    data-scroll
    data-scroll-id="data-collection"
    data-scroll-offset="50%"
  >
    <div class="container--small">
      <div class="w-70">
        <Title
          data-scroll
          data-scroll-offset="40%, 40%"
          data-scroll-call="line-by-line"
        >
          <div v-force-i18n="{ locale: $i18n.locale, message: $t('title') }">
            {{ $t('title') }}
          </div>
        </Title>
        <SmallText
          alignment="left"
          data-scroll
          data-scroll-offset="40%, 40%"
          data-scroll-call="line-by-line"
        >
          <span
            v-force-i18n="{ locale: $i18n.locale, message: $t('text') }"
            v-html="$t('text')"
          />
        </SmallText>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Typography/Title'
import SmallText from '@/components/Typography/SmallText'

export default {
  i18n: {
    messages: {
      it: {
        title: 'Processamento dati',
        text: 'Il processo di acquisizione dei dati e ricostruzione dei volumi è determinante per il calcolo delle tempistiche di scansione. L’algoritmo con cui opera <strong>STG-One</strong> è in grado di sincronizzare in parallelo la fase di acquisizione immagine e quella di ricostruzione dei volumi, riducendo al massimo il tempo totale di scansione.'
      },
      en: {
        title: 'Data processing',
        text: 'Data acquisition and volume reconstruction are crucial for calculating scan speed. <strong>STG-One</strong> algorithm allows to synchronise in parallel both the acquisition and reconstruction phase at the same time, optimising cycle duration.'
      }
    }
  },
  components: {
    Title,
    SmallText
  }
}
</script>

<style lang="scss" scoped>
.data-collection {
  margin-top: 50vh;
  height: 50vh;
  .w-70 {
    overflow-y: hidden;
    @media all and (min-width: 1024px) {
      width: 70%;
    }
  }
}
</style>
