<template>
  <div
    v-canvas
    class="canvas-container"
    :class="section"
  >
    <div class="overlay" />
    <div
      v-if="!appReady"
      class="placeholder-image"
    >
      <img src="/frames/introduction/DIMENSIONE_JPG_000.jpg">
    </div>
    <canvas
      id="main-canvas"
      ref="canvas"
    />
    <transition name="slide">
      <div
        v-if="section === 'introduction' && showScrollSuggestion && scrollPosition < 10"
        v-scroll-button
        class="scroll-down-button"
        @click="scrollDown"
      >
        <ScrollDownButton
          type="small"
          :is-scroll-down="true"
          side="down"
          class="button"
        >
          Scroll down
        </ScrollDownButton>
      </div>
    </transition>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters, mapMutations } from 'vuex'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import dataset from '@/dataset/frame-by-frame-animations'
import ScrollDownButton from '@/components/ScrollDownButton'

gsap.registerPlugin(ScrollTrigger)

let context

export default {
  directives: {
    canvas: {
      inserted (el) {
        const canvas = el.querySelector('canvas')
        let vh = window.innerHeight * 0.01
        canvas.style.setProperty('--vh', `${vh}px`)
        context = canvas.getContext('2d')
        canvas.width = window.innerWidth
        canvas.height = window.innerHeight
        window.addEventListener('resize', () => {
          canvas.width = window.innerWidth
          canvas.height = window.innerHeight
          vh = window.innerHeight * 0.01
          canvas.style.setProperty('--vh', `${vh}px`)
        })
      }
    },
    scrollButton: {
      inserted (el) {
        el.parentNode.removeChild(el)
        document.querySelector('#app').prepend(el)
      }
    }
  },
  components: {
    ScrollDownButton
  },
  props: {
    section: {
      type: String,
      required: true
    }
  },
  data: () => ({
    scrollTimeline: null,
    lastFrame: 0,
    showScrollSuggestion: false,
    stg: {
      frame: 1
    },
    currentImg: 0
  }),
  computed: {
    ...mapGetters({
      images: 'images',
      scroller: 'scroller',
      scrollPosition: 'scrollPosition',
      scrollDirection: 'scrollDirection',
      introVideoHasPlayed: 'introVideoHasPlayed',
      appReady: 'appReady'
    }),
    scrollLimit: {
      get () {
        if (this.sectionData.sections?.length) {
          return this.sectionData.sections.reduce((acc, curr) => {
            return acc + document.querySelector(`[data-scroll-id=${curr}`).offsetHeight
          }, 0)
        }
        return this.scroller().scroll.currentElements[this.section].section?.limit.y
      }
    },
    sectionData: {
      get () {
        return dataset[this.section]
      }
    },
    canDrawImage: {
      get () {
        if (!this.introVideoHasPlayed || this.lastFrame === 0) return true
        if (this.scrollDirection === 'up') return true
        return this.lastFrame < this.stg.frame
      }
    }
  },
  watch: {
    scrollPosition: {
      handler () {
        gsap.ticker.add(this.timelineProgress)
      },
      immediate: true
    },
    appReady: {
      handler (isAppReady) {
        if (!isAppReady) return
        this.scrollTimeline = gsap.timeline({ paused: true })
          .fromTo(this.stg,
            { frame: this.sectionData.initialFrame },
            {
              frame: this.sectionData.totalFrames,
              onUpdate: this.render
            })
      },
      immediate: true
    }
  },
  mounted () {
    this.render()
    if (!this.sectionData.autoplay) return
    this.playVideo()
    window.addEventListener('resize', () => {
      if (this.$refs.canvas.offsetWidth < 768) {
        this.fitCanvas()
        return
      }
      this.fillCanvas()
    })
  },
  methods: {
    ...mapMutations({ setAppReady: 'setAppReady', setIntroVideoHasPlayed: 'setIntroVideoHasPlayed' }),
    scrollDown () {
      this.scroller().scrollTo(window.innerHeight)
    },
    playVideo () {
      if (this.introVideoHasPlayed && !this.sectionData.autoplay) return
      this.$emit('video-started', this.section)
      gsap.timeline()
        .fromTo(this.stg,
          {
            frame: 0
          },
          {
            frame: this.sectionData.initialFrame,
            duration: this.sectionData.videoDuration,
            ease: this.sectionData.videoEase,
            onUpdate: () => this.render(true),
            onComplete: () => {
              this.setIntroVideoHasPlayed(true)
            }
          }, 0)
        .add(() => {
          this.$nextTick(() => {
            this.scroller().start()
            this.showScrollSuggestion = true
          })
        }, '+=0.2')
    },
    timelineProgress () {
      if (!this.scrollTimeline) return
      const progress = this.scrollPosition / this.scrollLimit
      // if (this.section === 'ring_rotation') console.table({ progress, pos: this.scrollPosition, lim: this.scrollLimit })
      if (progress > 1) return
      this.scrollTimeline.progress(progress)
    },
    fillCanvas () {
      const img = this.images[this.section][parseInt(this.stg.frame)]
      const canvas = this.$refs.canvas
      const scale = Math.max(canvas.width / img.width, canvas.height / img.height)
      const x = (canvas.width / 2) - (img.width / 2) * scale
      const y = (canvas.height / 2) - (img.height / 2) * scale
      context.drawImage(img, x, y, img.width * scale, img.height * scale)
      this.currentImg = parseInt(this.stg.frame)
    },
    fitCanvas () {
      const img = this.images[this.section][parseInt(this.stg.frame)]
      const canvas = this.$refs.canvas
      const scalingFactor = Math.min((canvas.width / img.width), (canvas.height / img.height)) * 1.4
      const width = img.width * scalingFactor
      const height = img.height * scalingFactor
      const dx = (canvas.width - width) / 2
      const dy = (canvas.height - height) - 100
      context.drawImage(img, dx, dy, width, height)
      this.currentImg = parseInt(this.stg.frame)
    },
    render (renderPrevInitialFrame = false) {
      if (!this.$refs.canvas) return
      if (!this.images) return
      if (!renderPrevInitialFrame && this.stg.frame < this.sectionData.initialFrame) return
      if (this.stg.frame > this.sectionData.totalFrames - 1) return
      if (!this.images[this.section][parseInt(this.stg.frame)]) return
      if (!this.canDrawImage) return
      if (this.currentImg === parseInt(this.stg.frame)) return
      context.clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
      if (this.$refs.canvas.offsetWidth < 768) {
        this.fitCanvas()
        this.lastFrame = this.stg.frame
        return
      }
      this.fillCanvas()
      this.lastFrame = this.stg.frame
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;

.ring_rotation {
  @media all and (min-width: 1024px) {
    transform: scale(0.8);
    transform-origin: left bottom;
  }
}
.placeholder-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  img {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    object-fit: contain;
    @media all and (min-width: 768px) {
      object-fit: cover;
    }
  }
}
.canvas-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  .overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(c.$bg,0.95) 0%, rgba(c.$bg,0.9) 19%, rgba(c.$bg,0.5) 28%, rgba(c.$bg,0) 60%);
  }
}

.scroll-down-button {
  position: fixed;
  bottom: 2rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 51;
  .button {
    pointer-events: auto;
  }
}

.ring_rotation {
  .overlay {
    position: absolute;
    top: -15%;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: linear-gradient(to bottom, rgba(c.$bg,0.95) 0%, rgba(c.$bg,0.9) 19%, rgba(c.$bg,0.5) 28%, rgba(c.$bg,0) 60%);
  }
}
</style>
