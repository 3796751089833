<template>
  <div
    id="payoff"
    data-scroll
    data-scroll-id="intro-payoff"
    class="container"
  >
    <div class="section">
      <div
        class="animation-container"
        data-scroll
        data-scroll-offset="40%, 40%"
        data-scroll-call="char-by-char"
        data-scroll-repeat
        data-scrolltosection
      >
        <Title>
          <span v-force-i18n="{ locale: $i18n.locale, message: $t('line1') }">
            {{ $t('line1') }}
          </span>
          <br>
          <span v-force-i18n="{ locale: $i18n.locale, message: $t('line2.start') }">
            {{ $t('line2.start') }}
          </span>
          <strong>
            <span v-force-i18n="{ locale: $i18n.locale, message: $t('line2.strong') }">
              {{ $t('line2.strong') }}
            </span>
          </strong>
          <span v-force-i18n="{ locale: $i18n.locale, message: $t('line2.end') }">
            {{ $t('line2.end') }}
          </span>
          <br>
          <span v-force-i18n="{ locale: $i18n.locale, message: $t('line3.start') }">
            {{ $t('line3.start') }}
          </span>
          <strong>
            <span v-force-i18n="{ locale: $i18n.locale, message: $t('line3.strong') }">
              {{ $t('line3.strong') }}
            </span>
          </strong>
        </Title>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/Typography/Title'

export default {
  i18n: {
    messages: {
      it: {
        line1: 'Su misura per te.',
        line2: {
          start: 'Per ',
          strong: ' rivelare ',
          end: ' quello che'
        },
        line3: {
          start: 'gli altri non ',
          strong: 'rivelano.'
        }
      },
      en: {
        line1: 'Custom-made',
        line2: {
          start: 'to ',
          strong: ' reveal ',
          end: ' what'
        },
        line3: {
          start: 'others ',
          strong: "don't."
        }
      }
    }
  },
  components: {
    Title
  }
}
</script>

<style lang="scss" scoped>
.section {
  .animation-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    @media all and (min-width: 1024px) {
      width: 65%;
      margin-left: 45%;
    }
    ::v-deep h2 {
      strong {
        display: inline-block;
      }
    }
  }
}
</style>
