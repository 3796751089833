<template>
  <h3 :class="alignment">
    <slot />
  </h3>
</template>

<script>
export default {
  props: {
    alignment: {
      type: String,
      default: () => 'center',
      validator: value => ['left', 'center', 'right']
    }
  }
}
</script>
<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/utils/font-size' as fz;

h3 {
  --fw: 600;
  color: #fff;
  line-height: 1.15;
  @include fz.display(24, 38);
  letter-spacing: -1.5px;
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  strong {
    color: c.$main;
  }
}
</style>
