<template>
  <div
    class="topbar"
    :class="scrollDirection === 'down' ? 'hide' : 'show'"
  >
    <div class="bg" />
    <div class="wrapper">
      <div />
      <div>
        <Logo @click.native="scrollToTop" />
      </div>
      <div>
        <LocaleButton />
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import LocaleButton from './LocaleButton'
import { mapGetters } from 'vuex'

export default {
  components: {
    Logo,
    LocaleButton
  },
  computed: {
    ...mapGetters({ scrollDirection: 'scrollDirection', scroller: 'scroller' })
  },
  methods: {
    scrollToTop () {
      if (!this.scroller()) return
      this.scroller().scrollTo(0, {
        duration: Math.min(1000, this.scrollPosition * 0.5)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem 1rem;
  display: flex;
  z-index: 50;
  width: 100%;
  justify-content: center;
  transition: transform 1.2s e.$in-out;
  &.hide {
    transform: translateY(-100%);
  }
  &.show {
    transform: translateY(0);
  }
  ::v-deep {
    svg {
      width: 9.5vw;
      min-width: 6rem;
    }
  }
  & > * {
    z-index: 2;
  }
  .bg {
    position: absolute;
    width: 100%;
    height: 150%;
    z-index: 1;
    left: 0;
    top: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(c.$bg, 1) 0%, rgba(c.$bg, 0.8) 60%, rgba(c.$bg, 0) 100%);
  }
  .wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    position: relative;
    padding: 0 3vw;
    & > div {
      width: 100%;
      display: flex;
      &:nth-child(2) {
        justify-content: center;
      }
      &:nth-child(3) {
        justify-content: flex-end;
      }
    }
  }
}
</style>
