<template>
  <div>
    <div class="container-content">
      <h1>{{ $t('title') }}</h1>
      <a href="/">
        <svg
          viewBox="0 0 40 12"
          style="enable-background: new 0 0 40 12;"
        >
          <path d="M0.2,5.5c-0.3,0.3-0.3,0.7,0,1l4.6,4.6c0.3,0.3,0.7,0.3,1,0c0.3-0.3,0.3-0.7,0-1L1.8,6l4.1-4.1 c0.3-0.3,0.3-0.7,0-1c-0.3-0.3-0.7-0.3-1,0L0.2,5.5z M40,5.3H0.7v1.5H40V5.3z" />
        </svg>
        {{ $t('backlink') }}
      </a>
    </div>
    <SiteFooter />
  </div>
</template>

<script>
import SiteFooter from './SiteFooter'
export default {
  components: {
    SiteFooter
  },
  i18n: {
    messages: {
      it: {
        title: 'Pagina non trovata',
        backlink: 'Torna alla home'
      },
      en: {
        title: 'Page not found',
        backlink: 'Back to home'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;

.container-content {
  padding-top: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    padding-bottom: 2rem;
  }
  a {
    font-weight: bold;
    color: c.$main;
    text-decoration: none;
    cursor: pointer;
    svg {
      margin-right: 30px;
      padding-top: 5px;
      width: 34px;
      @media screen and (min-width: 1024px) {
        width: 45px;
      }
      path {
        fill: c.$main;
      }
    }
    &:hover {
      opacity: 0.8;
      svg {
        animation: slide 1s ease-in-out infinite;
        @keyframes slide {
          0%,
          100% {
            transform: translate(0, 0);
          }

          50% {
            transform: translate(10px, 0);
          }
        }
      }
    }
  }
}
#footer {
  opacity: 1;
}
</style>
