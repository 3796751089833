<template>
  <div>
    <Topbar />
    <div class="container">
      <h1>Privacy Policy</h1>
      I Cookie sono costituiti da porzioni di codice installate all’interno del browser che assistono il Titolare nell’erogazione del Servizio in base alle finalità descritte. Alcune delle finalità di installazione dei Cookie potrebbero, inoltre, necessitare del consenso dell’Utente.
      <br>
      Quando l’installazione di Cookies avviene sulla base del consenso, tale consenso può essere revocato liberamente in ogni momento seguendo le istruzioni contenute in questo documento.
      <br><br>
      <b>Cookie tecnici e di statistica aggregata</b>
      <br>
      <b>Attività strettamente necessarie al funzionamento</b><br>
      Questo Sito Web utilizza Cookie per salvare la sessione dell’Utente e per svolgere altre attività strettamente necessarie al funzionamento di questo Sito Web, ad esempio in relazione alla distribuzione del traffico.
      <br><br>
      <b>Attività di salvataggio delle preferenze, ottimizzazione e statistica</b><br>
      Questo Sito Web utilizza Cookie per salvare le preferenze di navigazione ed ottimizzare l’esperienza di navigazione dell’Utente. Fra questi Cookie rientrano, ad esempio, quelli per impostare la lingua e la valuta o per la gestione di statistiche da parte del Titolare del sito.
      <br><br>
      <b>Altre tipologie di Cookie o strumenti terzi che potrebbero installarne</b><br>
      Alcuni dei servizi elencati di seguito raccolgono statistiche in forma aggregata ed anonima e potrebbero non richiedere il consenso dell’Utente o potrebbero essere gestiti direttamente dal Titolare – a seconda di quanto descritto – senza l’ausilio di terzi.
      <br>
      Qualora fra gli strumenti indicati in seguito fossero presenti servizi gestiti da terzi, questi potrebbero – in aggiunta a quanto specificato ed anche all’insaputa del Titolare – compiere attività di tracciamento dell’Utente. Per informazioni dettagliate in merito, si consiglia di consultare le privacy policy dei servizi elencati.
      <br><br>
      <b>Gestione dei tag</b><br>
      Questo tipo di servizi è funzionale alla gestione centralizzata dei tag o script utilizzati su questo Sito Web.
      <br>
      L’uso di tali servizi comporta il fluire dei Dati dell’Utente attraverso gli stessi e, se del caso, la loro ritenzione.
      <br><br>
      <b>Google Tag Manager (Google LLC)</b><br>
      Google Tag Manager è un servizio di gestione dei tag fornito da Google LLC.<br>
      Dati Personali raccolti: Cookie e Dati di utilizzo.<br>
      Luogo del trattamento: Stati Uniti – <a
        class=""
        href="http://www.google.com/intl/it/policies/privacy/"
      >Privacy Policy</a>. Soggetto aderente al Privacy Shield.<br>
      <br>
      <b>Pubblicità</b><br>
      Questo tipo di servizi consentono di utilizzare i Dati dell’Utente per finalità di comunicazione commerciale in diverse forme pubblicitarie, quali il banner, anche in relazione agli interessi dell’Utente.
      <br>
      Ciò non significa che tutti i Dati Personali vengano utilizzati per questa finalità. Dati e condizioni di utilizzo sono indicati di seguito.
      <br>
      Alcuni dei servizi di seguito indicati potrebbero utilizzare Cookie per identificare l’Utente o utilizzare la tecnica del behavioral retargeting, ossia visualizzare annunci pubblicitari personalizzati in base agli interessi e al comportamento dell’Utente, rilevati anche al di fuori di questo Sito Web. Per avere maggiori informazioni in merito, ti suggeriamo di verificare le informative privacy dei rispettivi servizi.
      <br>In aggiunta alle possibilità di effettuare l’opt-out offerte dai servizi di seguito riportati, l’Utente può optare per l’esclusione rispetto alla ricezione dei cookie relativi ad un servizio terzo, visitando la <a href="http://optout.networkadvertising.org/">pagina di opt-out del Network Advertising Initiative</a>.
      <br><br>
      <b>Facebook Audience Network (Facebook, Inc.)</b><br>
      Facebook Audience Network è un servizio pubblicitario fornito da Facebook, Inc.
      <br><br>
      <b>Per una comprensione dell’utilizzo dei dati da parte di Facebook, si prega di consultare</b> <a href="http://www.facebook.com/about/privacy/">la normativa sui dati di Facebook</a>. Per permettere il funzionamento di Facebook Audience Network, questo Sito Web può utilizzare alcuni identificatori per dispositivi mobili (tra cui Android Advertising ID o Advertising Identifier per OS) ovvero tecnologie simili ai cookie. Tra le modalità attraverso cui Audience Network propone messaggi pubblicitari all’Utente, vi è anche l’utilizzo delle preferenze pubblicitarie di quest’ultimo. L’Utente può controllare la condivisione delle proprie preferenze pubblicitarie all’interno delle <a href="http://www.facebook.com/settings?tab=ads">impostazioni Ad di Facebook</a>. L’Utente può effettuare l’opt-out da alcune funzioni di Audience Network targeting mediante le impostazioni del proprio dispositivo. Ad esempio, può modificare le impostazioni sulla pubblicità disponibili per i dispositivi mobili, oppure seguire le istruzioni applicabili ad Audience Network eventualmente presenti all’interno di questa privacy policy.
      <br>
      Dati Personali raccolti: Cookie, Dati di utilizzo e identificatori univoci di dispositivi per la pubblicità (Google Advertiser ID o identificatore IDFA, per esempio).
      <br>
      Luogo del trattamento: Stati Uniti – <a href="http://www.facebook.com/about/privacy/">Privacy Policy</a> – <a href="http://www.facebook.com/settings?tab=ads">Opt Out</a>. Soggetto aderente al Privacy Shield.
      <br><br>
      <b>Remarketing e behavioral targeting</b><br>
      Questo tipo di servizi consente a questo Sito Web ed ai suoi partner di comunicare, ottimizzare e servire annunci pubblicitari basati sull’utilizzo passato di questo Sito Web da parte dell’Utente.
      <br>
      Questa attività viene effettuata tramite il tracciamento dei Dati di Utilizzo e l’uso di Cookie, informazioni che vengono trasferite ai partner a cui l’attività di remarketing e behavioral targeting è collegata.
      <br>
      In aggiunta alle possibilità di effettuare l’opt-out offerte dai servizi di seguito riportati, l’Utente può optare per l’esclusione rispetto alla ricezione dei cookie relativi ad un servizio terzo, visitando la <a href="http://optout.networkadvertising.org/">pagina di opt-out del Network Advertising Initiative</a>.
      <br><br>
      <b>Facebook Remarketing (Facebook, Inc.)</b><br>
      Facebook Remarketing è un servizio di remarketing e behavioral targeting fornito da Facebook, Inc. che collega l’attività di questo Sito Web con il network di advertising Facebook.
      <br>
      Dati Personali raccolti: Cookie e Dati di utilizzo.
      <br>
      Luogo del trattamento: Stati Uniti – <a href="http://www.facebook.com/about/privacy/">Privacy Policy</a> – <a href="http://www.aboutads.info/choices/">Opt Out</a>. Soggetto aderente al Privacy Shield.
      <br><br>
      <b>Facebook Custom Audience (Facebook, Inc.)</b><br>
      Facebook Custom Audience è un servizio di remarketing e behavioral targeting fornito da Facebook, Inc. che collega l’attività di questo Sito Web con il network di advertising Facebook.
      <br>
      Dati Personali raccolti: Cookie e email.
      <br>
      Luogo del trattamento: Stati Uniti – <a href="http://www.facebook.com/about/privacy/">Privacy Policy</a> – <a href="http://www.aboutads.info/choices/">Opt Out</a>. Soggetto aderente al Privacy Shield.
      <br><br>
      <b>Statistica</b><br>
      I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.
      <br><br>
      <b>Google Analytics (Google Inc.)</b><br>
      Google Analytics è un servizio di analisi web fornito da Google Inc. (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questo Sito Web, compilare report e condividerli con gli altri servizi sviluppati da Google.
      <br><br>
      Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.<br>
      Dati Personali raccolti: Cookie e Dati di utilizzo.<br>
      Luogo del trattamento: Stati Uniti – <a href="http://www.google.com/intl/it/policies/privacy/">Privacy Policy</a> – <a href="http://tools.google.com/dlpage/gaoptout?hl=it">Opt Out</a>.
      <br><br>
      <b>WordPress Stat (Automattic Inc.)</b><br>
      WordPress Stats è un servizio di statistica fornito da Automattic Inc.<br>
      Dati Personali raccolti: Cookie e Dati di utilizzo.<br>
      Luogo del trattamento: Stati Uniti – <a href="http://automattic.com/privacy/">Privacy Policy</a>.<br>
      <br>
      <b>Come posso esprimere il consenso all’installazione di Cookie?</b><br>
      In aggiunta a quanto indicato in questo documento, l’Utente può gestire le preferenze relative ai Cookie direttamente all’interno del proprio browser ed impedire – ad esempio – che terze parti possano installarne. Tramite le preferenze del browser è inoltre possibile eliminare i Cookie installati in passato, incluso il Cookie in cui venga eventualmente salvato il consenso all’installazione di Cookie da parte di questo sito. L’Utente può trovare informazioni su come gestire i Cookie con alcuni dei browser più diffusi ad esempio ai seguenti indirizzi: <a href="http://support.google.com/chrome/answer/95647?hl=it&p=cpn_cookies">Google Chrome</a>, <a href="http://support.mozilla.org/it/kb/Attivare%20e%20disattivare%20i%20cookie">Mozilla Firefox</a>, <a href="http://support.apple.com/kb/PH21411">Apple Safari</a> e <a href="http://windows.microsoft.com/it-it/windows-vista/block-or-allow-cookies">Microsoft Internet Explorer</a>.
      <br>
      Con riferimento a Cookie installati da terze parti, l’Utente può inoltre gestire le proprie impostazioni e revocare il consenso visitando il relativo link di opt out (qualora disponibile), utilizzando gli strumenti descritti nella privacy policy della terza parte o contattando direttamente la stessa.
      <br>
      Fermo restando quanto precede, l’Utente può avvalersi delle informazioni fornite da <a href="http://www.youronlinechoices.eu/">EDAA</a> (UE), <a href="http://www.networkadvertising.org/understanding-digital-advertising">Network Advertising Initiative</a> (USA) e <a href="http://www.aboutads.info/consumers/">Digital Advertising Alliance</a> (USA), <a href="http://youradchoices.ca/understanding-online-advertising/">DAAC</a> (Canada), <a href="http://www.ddai.info/optout">DDAI</a> (Giappone) o altri servizi analoghi. Con questi servizi è possibile gestire le preferenze di tracciamento della maggior parte degli strumenti pubblicitari. Il Titolare, pertanto, consiglia agli Utenti di utilizzare tali risorse in aggiunta alle informazioni fornite dal presente documento.
      <br><br>
      <b>Titolare del Trattamento dei Dati</b><br>
      Vetorix Engineering S.r.l. Unipersonale, Via dell’Artigianato, 12, 30020 Marcon – VE – ITALY<br>
      P.Iva 03706440272<br>
      Indirizzo email del Titolare: <a href="mailto:info@vetorixndi.com">info@vetorixndi.com</a>
      <br>
      Dal momento che l’installazione di Cookie e di altri sistemi di tracciamento operata da terze parti tramite i servizi utilizzati all’interno di questo Sito Web non può essere tecnicamente controllata dal Titolare, ogni riferimento specifico a Cookie e sistemi di tracciamento installati da terze parti è da considerarsi indicativo. Per ottenere informazioni complete, l’Utente è invitato a consultare la privacy policy degli eventuali servizi terzi elencati in questo documento.
      <br>
      Vista l’oggettiva complessità di identificazione delle tecnologie basate sui Cookie l’Utente è invitato a contattare il Titolare qualora volesse ricevere qualunque approfondimento relativo all’utilizzo dei Cookie stessi tramite questo Sito Web.
      <br><br>
      <b>Definizioni e riferimenti legali</b><br>
      <b>Dati Personali (o Dati)</b><br>
      Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.
      <br><br>
      <b>Dati di Utilizzo</b><br>
      Sono le informazioni raccolte automaticamente attraverso questo Sito Web (anche da applicazioni di parti terze integrate in questo Sito Web), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questo Sito Web, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.
      <br><br>
      <b>Utente</b><br>
      L’individuo che utilizza questo Sito Web che, salvo ove diversamente specificato, coincide con l’Interessato.
      <br><br>
      <b>Interessato</b><br>
      La persona fisica cui si riferiscono i Dati Personali.
      <br><br>
      <b>Responsabile del Trattamento (o Responsabile)</b><br>
      La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.
      <br><br>
      <b>Titolare del Trattamento (o Titolare)</b><br>
      La persona fisica o giuridica, l’autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questo Sito Web. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questo Sito Web.
      <br><br>
      <b>Questo Sito Web (o questa Applicazione)</b><br>
      Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.
      <br><br>
      <b>Servizio</b><br>
      Il Servizio fornito da questo Sito Web così come definito nei relativi termini (se presenti) su questo sito/applicazione.
      <br><br>
      <b>Unione Europea (o UE)</b><br>
      Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.
      <br><br>
      <b>Cookie</b><br>
      Piccola porzione di dati conservata all’interno del dispositivo dell’Utente.
      <br><br>
      <b>Preferenze cookies</b><br>
      Fai clic <a href="javascript:Cookiebot.renew()">qui</a> per personalizzare le impostazioni dei cookies.
      <br><br>
      <b>Riferimenti legali</b><br>
      La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi, inclusi gli artt. 13 e 14 del Regolamento (UE) 2016/679.
      <br>
      Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questo Sito Web.
    </div>
    <SiteFooter />
  </div>
</template>

<script>
import Topbar from '@/components/Topbar'
import SiteFooter from '@/components/SiteFooter'
export default {
  name: 'Privacy Policy',
  components: {
    Topbar,
    SiteFooter
  },
  metaInfo () {
    return {
      title: 'Privacy Policy | STG-One'
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;

.container {
  padding-top: 10rem;
  h1 {
    text-align: center;
    padding-bottom: 2rem;
  }
  a {
    color: c.$main;
  }
}
#footer {
  opacity: 1;
}
</style>
