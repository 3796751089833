<template>
  <div
    data-scroll
    data-scroll-id="intro-main"
  >
    <div
      ref="loaderbg"
      class="loader-bg"
    />
    <div class="container">
      <div class="section">
        <div
          class="title"
          data-scroll
          data-scroll-call="slide-in"
          data-scroll-repeat
          data-scroll-offset="20%, 40%"
          data-scrolltosection
        >
          <MainTitle
            ref="loader"
            class="loader"
            type="loader"
          >
            <template #title>
              Stg-One
            </template>
            <template #subtitle>
              <div v-force-i18n:message="{ locale: $i18n.locale, message: { it: 'Tomografia assiale computerizzata', en: 'Computed  axial tomography'}}">
                {{ $t('subtitle') }}
              </div>
            </template>
          </MainTitle>
          <MainTitle
            ref="mainTitle"
            class="main"
          >
            <template #title>
              Stg-One
            </template>
            <template #subtitle>
              <div v-force-i18n:message="{ locale: $i18n.locale, message: $t('subtitle') }">
                {{ $t('subtitle') }}
              </div>
            </template>
          </MainTitle>
          <div class="loader-percentage-wrapper">
            <div
              ref="loaderPercentage"
              class="loader-percentage"
            >
              {{ loaderPercentage }} %
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainTitle from '@/components/Typography/MainTitle'
import { mapGetters } from 'vuex'
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
import dataset from '@/dataset/frame-by-frame-animations'

gsap.registerPlugin(SplitText)

export default {
  i18n: {
    messages: {
      it: {
        subtitle: 'Tomografia Assiale Computerizzata'
      },
      en: {
        subtitle: 'Computed Axial Tomography'
      }
    }
  },
  components: {
    MainTitle
  },
  data: () => ({
    canLoaderEnd: false,
    endLoaderStart: false,
    loaderPercentage: 0,
    loader: undefined
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller', images: 'images' })
  },
  watch: {
    'images.introduction': {
      handler () {
        gsap.ticker.add(this.timelineProgress)
      },
      immediate: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.startLoader()
    })
  },
  methods: {
    scrollToNextSection () {
      this.scroller().scrollTo('[data-scroll-id=section1]', { duration: 2000 })
    },
    startLoader () {
      this.loader = gsap.timeline({
        paused: true,
        onComplete: () => {
          this.$emit('play-video')
          this.endLoader()
        },
        onUpdate: () => {
          const progress = this.$refs.loader.$refs.progress.offsetWidth * 100 / this.$refs.loader.$refs.progress.parentNode.offsetWidth
          this.loaderPercentage = Math.min(parseInt(progress), 99)
          const x = this.$refs.loaderPercentage.parentNode.offsetWidth * progress / 100
          gsap.set(this.$refs.loaderPercentage, { x })
        }
      })
        .to(this.$refs.loader.$el, { opacity: 1, ease: 'power4.inOut', duration: 0.6 }, 0)
        .to(this.$refs.loader.$refs.progress, { width: '100%', ease: 'expo.inOut', duration: 8.4 }, 0.6)
        .add(() => {
          this.$refs.loader.$refs.main.classList.add('fill')
        }, 9)
    },
    endLoader () {
      this.endLoaderStart = true
      const main = this.$refs.mainTitle.$refs.mainTitle
      const split = new SplitText(main, { type: 'lines, words, chars', charsClass: 'char' })
      const chars = split.chars

      gsap.set(this.$refs.loaderbg, { skewY: -4, transformOrigin: 'top' })
      gsap.set(this.$refs.mainTitle.$refs.mainTitle, { opacity: 1 })

      const contactsButtonParams = window.innerWidth > 1024
        ? { x: 0, ease: 'expo.out', duration: 1.6 }
        : { yPercent: -100, ease: 'expo.out', duration: 1.6 }
      gsap.timeline({
        delay: 2
      })
        .add(() => {
          this.loaderPercentage = 100
        }, 0)
        .to(this.$refs.loaderbg, { scaleY: 0, skewY: 12, ease: 'power4.easeInOut', duration: 1.8 }, 0)
        .to(this.$refs.loader.$refs.progress, { opacity: 0, scale: 1.2, ease: 'power4.inOut', duration: 0.6 }, 0.1)
        .to(chars, {
          scale: 1,
          webkitFilter: 'blur(0px)',
          opacity: 1,
          duration: 4.8,
          ease: 'expo.out',
          stagger: {
            amount: 1,
            from: 'random'
          }
        }, 0.1)
        .to(this.$refs.loaderPercentage, { autoAlpha: 0, ease: 'power4.out', duration: 0.4 }, 0.2)
        .from('#main-canvas', { scale: 2.6, ease: 'expo.out', duration: 2.6 }, 0)
        .to('.contacts-button', contactsButtonParams, 1.8)
    },
    async timelineProgress () {
      if (!this.loader) return
      const { totalFrames } = dataset.introduction
      const progress = this.images.introduction.length / totalFrames
      // Fake loader
      await setTimeout(() => {}, 50)
      this.loader.progress(progress)
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/utils/font-size' as fz;

.loader-bg {
  position: fixed;
  top: -20vh;
  left: 0;
  width: 100vw;
  height: 160vh;
  background-color: darken(c.$bg, 20%);
  z-index: 10;
}

.container {
  z-index: 20;
  position: relative;
}

.title {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .loader {
    position: absolute;
    top: 0;
  }
  .main {
    position: relative;
    top: 0;
    left: 0;
  }
}

.loader-percentage-wrapper {
  width: 75%;
  position: relative;
  @media all and (min-width: 1024px) {
    width: 100%;
  }
  .loader-percentage {
    --fw: 400;
    position: absolute;
    left: 0;
    color: #444;
    @include fz.text(14, 18);
  }
}
</style>
