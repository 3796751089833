<template>
  <div
    ref="main"
    class="machine-track"
    :class="{ end }"
  >
    <div class="fixed-el">
      <div class="machine">
        <img
          ref="sideImage"
          src="/images/machine-side.png"
        >
      </div>
      <div class="track">
        <img src="/images/track.png">
      </div>
    </div>
    <div class="objects">
      <div
        v-for="i of 2"
        :key="i"
        class="scroll-area"
        :class="i === 1 ? 'real' : 'post'"
      >
        <div
          v-for="area of normalizeAreas"
          :key="area"
          ref="area"
          data-scroll
          class="wrapper"
        >
          <div>
            <img
              :src="`/images/areas/${i === 1 ? '' : 'POST_'}${areas[area].mainImage}`"
              :class="[ area.toLowerCase(), { 'to-flip': i === 2 && area === Object.keys(areas)[0] }]"
              @load="imagesLoaded++"
            >
          </div>
        </div>
        <div class="wrapper">
          <div />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import areas from '@/dataset/areas'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default {
  data: () => ({
    ready: false,
    timeline: null,
    progress: 0,
    oldProgress: 0,
    imagesLoaded: 0,
    scrollDirection: 'next',
    areas,
    end: false,
    currentArea: null
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' }),
    normalizeAreas: {
      get () {
        return Object.keys(this.areas)
          .filter((a) => this.areas[a].mainImage)
      }
    }
  },
  watch: {
    imagesLoaded: {
      handler (v) {
        if (v !== this.normalizeAreas.length * 2) return
        const machine = this.$refs.main.querySelector('.machine')
        const track = this.$refs.main.querySelector('.track')
        const scroll = this.$refs.main.querySelectorAll('.scroll-area')
        const init = () => {
          gsap.to(scroll, { scrollTo: { x: 'max' } })
        }

        init()
        window.addEventListener('resize', init)
        this.timeline = gsap.timeline({
          paused: true,
          onStart: () => {
            gsap.set('.to-flip', { opacity: 1 })
          },
          onReverseComplete: () => {
            this.areaSwitch(false)
          }
        })
          .add(() => this.areaSwitch(''))
          .to(track, { opacity: 1, ease: 'expo.inOut', duration: 0.8 })
          .to(machine, { x: 0, ease: 'expo.inOut', duration: 1 }, '-=0.4')

        this.normalizeAreas.map((area, i) => {
          const el = this.$refs.area[this.normalizeAreas.length - 1 - i]
          this.timeline
            // .add(() => this.areaSwitch(this.normalizeAreas[this.normalizeAreas.length - 1 - i]))
            .to(scroll, {
              scrollTo: { x: el },
              duration: 2,
              ease: 'none',
              onUpdate: () => {
                const containerScrollWidth = scroll[0].scrollWidth - scroll[0].offsetWidth / 2
                const scrollLeft = scroll[0].scrollLeft - scroll[0].offsetWidth / 2
                const i = parseInt(this.normalizeAreas.length * scrollLeft / containerScrollWidth + 1)
                if (this.currentArea === this.normalizeAreas[i]) return
                this.areaSwitch(this.normalizeAreas[i])
                gsap.set('.to-flip', { opacity: 1 })
              }
            })
        })
        this.timeline
          .to(machine, { x: -window.innerWidth / 1.5, ease: 'expo.inOut', duration: 1 })
          .to('.scroll-area.real', { clipPath: 'inset(0% 100% 0% 0%', ease: 'expo.inOut', duration: 1 }, '-=1')
          .to('.scroll-area.post', { clipPath: 'inset(0% 0% 0% 0%', ease: 'expo.inOut', duration: 1 }, '-=2')
          .to(track, { opacity: 0, ease: 'expo.inOut' })
      }
    },
    scroller: {
      handler (scroller) {
        if (!scroller || this.ready) return
        scroller().on('scroll', ({ currentElements }) => {
          if (!currentElements.nolimits) {
            gsap.ticker.remove(this.timelineProgress)
            return
          }
          this.progress = currentElements.nolimits.progress
          this.end = this.progress > 0.8
          gsap.ticker.add(this.timelineProgress)
          this.ready = true
        })
      },
      immediate: true
    }
  },
  methods: {
    timelineProgress () {
      if (!this.timeline) return
      // from 0.2 to 0.8 of section progress
      const progress = (this.progress - 0.2) / 0.6
      this.scrollDirection = progress > this.oldProgress
        ? 'next'
        : 'prev'
      this.timeline.progress(progress)
      this.oldProgress = progress
    },
    areaSwitch (area) {
      this.$emit('area-switch', { area, scrollDirection: this.scrollDirection })
      if (!area || !this.areas[area]) return
      this.currentArea = area
      gsap.to(this.$refs.sideImage, { scale: this.areas[area].size, ease: 'power4.in', duration: 0.6 })
    }
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/styles/easing' as e;

.machine-track {
  position: absolute;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  @media all and (min-width: 1024px) {
    top: auto;
    bottom: 2rem;
    transform: translate3d(0, 0, 0);
  }
  .fixed-el {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    height: 50%;
    @media all and (min-width: 768px) {
      height: 80%;
    }
    .machine {
      position: relative;
      z-index: 1;
      transform: translateX(-58vw);
      z-index: 20;
      height: 100%;
      img {
        height: 300px;
        width: auto;
        transform-origin: bottom;
        @media all and (min-width: 1024px) {
          height: 100%;
        }
        @media all and (min-width: 1600px) {
          height: 64vh;
        }
      }
    }
    .track {
      position: relative;
      bottom: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      z-index: 19;
      transition: opacity 0.4s e.$out;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .objects {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .scroll-area {
      overflow-x: scroll;
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      align-items: center;
      scrollbar-width: none;
      height: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
      .wrapper {
        position: relative;
        display: inline-block;
        height: 75%;
        @media all and (min-width: 768px) {
          height: 100%;
        }
        & > div {
          height: 100%;
          width: 100vw;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-bottom: 2rem;
          img {
            width: auto;
            position: relative;
            display: block;
            z-index: 2;
            height: 100%;
            @media all and (min-width: 1024px) {
              max-height: 70%;
            }
          }
        }
      }
      &.real {
        clip-path: inset(0% 50% 0% 0%);
        position: relative;
        z-index: 10;
      }
      &.post {
        position: absolute;
        left: 0;
        width: 100vw;
        z-index: 9;
        clip-path: inset(0% 0% 0% 50%);
        height: 100%;
      }
    }
  }
  &.end {
    .track {
      opacity: 0;
    }
    .scroll-area {
      .wrapper {
        & > div {
          img:not(.to-flip) {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>
