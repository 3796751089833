<template>
  <div class="description">
    <SmallText>
      {{ $t('main') }}
    </SmallText>
  </div>
</template>

<script>
import SmallText from '@/components/Typography/SmallText'

export default {
  i18n: {
    messages: {
      it: {
        main: 'Il sistema viene progettato e realizzato appositamente con la forma e le dimensioni di cui hai bisogno, per soddisfare qualsiasi esigenza. La versatilità e la precisione sono i nostri valori portanti, creiamo anche architetture standard a piatto rotante e soluzioni implementabili per l’automazione.'
      },
      en: {
        main: 'Designed and manufactured with size and shape you need, our system is ready to meet any requirements you may have. Versatility and accuracy are our core values, we also create standard turntable architectures and solutions for automation.'
      }
    }
  },
  components: {
    SmallText
  }
}
</script>

<style lang="scss" scoped>
.description {
  @media all and (min-width: 1024px) {
    width: 70%;
  }
}
</style>
