<template>
  <div class="container educational-plan">
    <div
      class="w-50"
    >
      <h2
        v-force-i18n="{ locale: $i18n.locale, message: $t('title') }"
        class="title"
        data-scroll
        data-scroll-call="line-by-line"
        data-scroll-offset="40%, 40%"
      >
        {{ $t('title') }}
      </h2>
      <SmallText
        v-force-i18n="{ locale: $i18n.locale, message: $t('text') }"
        alignment="left"
        inview-animation="line-by-line"
      >
        <span v-html="$t('text')" />
      </SmallText>
      <a
        href="http://www.vetorixndi.com"
        target="_blank"
        data-scroll
        data-scroll-call="slide-in"
        data-scroll-offset="40%, 40%"
      >
        <ArrowButton
          side="next"
          class="button"
        >
          {{ $t('button') }}
        </ArrowButton>
      </a>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton'
import SmallText from '@/components/Typography/SmallText'

export default {
  i18n: {
    messages: {
      it: {
        title: 'Oltre il supporto tecnico',
        text: '<strong>STG-One</strong> nasce dalla sintesi di innovazione ed esperienza nel laboratorio R&D di Vetorix, azienda che da oltre 30 anni ricerca e implementa processi per le ispezioni non distruttive con l’obiettivo di garantire la sicurezza delle persone. Siamo qui per offrirti un supporto non solo tecnico, ma anche strategico, che va oltre le funzionalità di <strong>STG-One</strong> per studiare insieme a te la soluzione migliore per le tue esigenze.',
        button: 'Scopri di più'
      },
      en: {
        title: 'Beyond technical support',
        text: '<strong>STG-One</strong> is the synthesis of innovation and experience of Vetorix R&D Lab, and it is the new evolution step of a company that has been researching and implementing processes for non-destructive inspections for over 30 years in many different sectors. We offer you technical and also strategic support which goes beyond the functionality of <strong>STG-One</strong>, studying together the best NDI process for your needs.',
        button: 'Read more'
      }
    }
  },
  components: {
    ArrowButton,
    SmallText
  }
}
</script>

<style lang="scss" scoped>
@use '~@/assets/styles/colors' as c;
@use '~@/assets/utils/font-size' as fz;

.educational-plan {
  height: 100vh;
  display: flex;
  margin-top: 6.5625rem;
  @media all and (min-width: 768px) {
    justify-content: flex-end;
    align-items: center;
    .w-50 {
      width: 50%;
    }
  }
  .w-50 {
    .title {
      --fw: 700;
      color: c.$main;
      @include fz.display(22, 24);
    }
    .button {
      margin-top: 2rem;
    }
  }
}
</style>
