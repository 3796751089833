<template>
  <div id="home">
    <ImagesLoader />
    <ContactsButton />
    <BackToTopButton />
    <transition
      name="frame-by-frame-animation"
    >
      <FrameByFrameAnimation
        v-if="currentSection === 'introduction'"
        ref="intro_fbf"
        key="introduction"
        class="introduction"
        section="introduction"
        @video-started="handleVideoStarted"
      />
    </transition>
    <transition
      name="frame-by-frame-animation"
    >
      <FrameByFrameAnimation
        v-if="currentSection === 'ring_rotation'"
        key="ring_rotation"
        class="ring_rotation"
        section="ring_rotation"
        @video-started="handleVideoStarted"
      />
    </transition>
    <LocomotiveScroll>
      <Topbar />
      <IntroMain
        class="introduction"
        data-scroll
        data-scroll-section
        @play-video="playVideo"
      />
      <IntroMainText
        data-scroll
        data-scroll-section
      />
      <IntroPayoff
        data-scroll
        data-scroll-section
      />
      <IntroData
        data-scroll
        data-scroll-section
      />
      <RingRotation
        ref="ring_rotation"
        data-scroll
        data-scroll-section
      />
      <DataCollection
        data-scroll
        data-scroll-section
      />
      <NoLimits
        data-scroll
        data-scroll-section
      />
      <VideoReel
        data-scroll
        data-scroll-section
      />
      <Contacts
        data-scroll
        data-scroll-section
      />
      <EducationalPlan
        data-scroll
        data-scroll-section
      />
      <Comparison
        data-scroll
        data-scroll-section
      />
      <Specs
        data-scroll
        data-scroll-section
      />
      <SiteFooter
        data-scroll
        data-scroll-section
      />
    </LocomotiveScroll>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Topbar from '@/components/Topbar'
import BackToTopButton from '@/components/BackToTopButton'
import ImagesLoader from '@/components/ImagesLoader'
import FrameByFrameAnimation from '@/components/FrameByFrameAnimation'
import IntroMain from '@/components/Sections/IntroMain'
import IntroMainText from '@/components/Sections/IntroMainText'
import IntroPayoff from '@/components/Sections/IntroPayoff'
import IntroData from '@/components/Sections/IntroData'
import RingRotation from '@/components/Sections/RingRotation'
import DataCollection from '@/components/Sections/DataCollection'
import NoLimits from '@/components/Sections/NoLimits'
import VideoReel from '@/components/Sections/VideoReel'
import Contacts from '@/components/Sections/Contacts'
import EducationalPlan from '@/components/Sections/EducationalPlan'
import Comparison from '@/components/Sections/Comparison'
import Specs from '@/components/Sections/Specs'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import SiteFooter from '@/components/SiteFooter'
import ContactsButton from '@/components/ContactsButton'

export default {
  name: 'TSGOne',
  i18n: {
    messages: {
      it: {
        titleSeo: 'STG-One | Tomografia Assiale Computerizzata',
        descriptionSeo: 'STG-One è il tuo alleato per controlli non distruttivi che scansiona in 3D anche oggetti di grandi dimensioni. Per prodotti sicuri. Tomografia Assiale Computerizzata creata su misura, versatile, che processa i dati attraverso un algoritmo senza eguali sviluppato nel nostro laboratorio.'
      },
      en: {
        titleSeo: 'STG-One | Computed Axial Tomography',
        descriptionSeo: 'STG-One is your partner for non-destructive testing that provides 3D data even on large objects. For the safety of your products. Computed Axial Tomography that’s custom made, versatile, and that acquires data through our unmatched innovative algorithm.'
      }
    }
  },
  components: {
    FrameByFrameAnimation,
    Topbar,
    BackToTopButton,
    ImagesLoader,
    IntroMain,
    IntroMainText,
    IntroPayoff,
    IntroData,
    RingRotation,
    NoLimits,
    VideoReel,
    Contacts,
    EducationalPlan,
    Comparison,
    Specs,
    LocomotiveScroll,
    DataCollection,
    SiteFooter,
    ContactsButton
  },
  data: () => ({
    introSections: [
      'intro-main',
      'intro-maintext',
      'intro-payoff',
      'intro-data'
    ],
    currentSection: 'introduction'
  }),
  computed: {
    ...mapGetters({ scroller: 'scroller' })
  },
  watch: {
    scroller: {
      handler (scroller) {
        if (!scroller) return
        scroller().on('scroll', ({ scroll, direction, currentElements }) => {
          const isIntro = (!Object.keys(currentElements).length && scroll.y < 4000) || this.introSections
            .some((s) => Object.keys(currentElements).indexOf(s) > -1)
          const isRingRotation = !!currentElements.ring_rotation

          if (isIntro && !isRingRotation) {
            this.currentSection = 'introduction'
            return
          }
          if (!isIntro && isRingRotation) {
            this.currentSection = 'ring_rotation'
            return
          }
          if (isIntro && isRingRotation) {
            this.currentSection = direction === 'down'
              ? 'ring_rotation'
              : 'introduction'
            return
          }
          this.currentSection = null
        })
      }
    }
  },
  methods: {
    playVideo () {
      this.$refs.intro_fbf.playVideo()
    },
    handleVideoStarted (section) {
      if (this.$refs[section] && this.$refs[section].play) this.$refs[section].play()
    }
  },
  metaInfo () {
    return {
      title: this.$t('titleSeo'),
      meta: [
        { name: 'description', content: this.$t('descriptionSeo') },
        { name: 'og:title', content: this.$t('titleSeo') },
        { name: 'og:description', content: this.$t('descriptionSeo') },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', itemprop: 'image', content: 'https://stg-one.com/images/video-fallback.jpg' },
        { property: 'og:image:type', content: 'image/jpg' },
        { property: 'og:image:width', content: '300' },
        { property: 'og:image:height', content: '200' }
      ]
    }
  }
}
</script>

<style lang="scss">
@use '~@/assets/styles/easing' as e;

.frame-by-frame-animation-enter-active,
.frame-by-frame-animation-leave-active {
  transition: opacity 1.2s e.$out, transform 1.2s e.$out;
}

.frame-by-frame-animation-enter-active {
  transition-delay: 1.2s;
}

.frame-by-frame-animation-enter {
  opacity: 0;
  transform: scale(0.9) translateX(-100px);
}

.frame-by-frame-animation-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.introduction {
  z-index: 49;
  position: relative;
}
</style>
