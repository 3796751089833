<template>
  <div
    class="rotation-ring"
    data-scroll
    data-scroll-id="ring_rotation"
    data-scroll-offset="50%"
  >
    <TechnicalData ref="technicalData" />
  </div>
</template>

<script>
import TechnicalData from './TechnicalData'

export default {
  components: {
    TechnicalData
  },
  methods: {
    play () {
      this.$refs.technicalData.play()
    }
  }
}
</script>
